import {
  Card,
  CardBody,
  CardFooter,
  Label,
  MultipleFileUpload,
  MultipleFileUploadMain,
  MultipleFileUploadStatus,
  MultipleFileUploadStatusItem,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core'

import { FileIcon, UploadIcon } from '@patternfly/react-icons'
import { useContext, useState } from 'react'
import { Anexo } from '../../clients'

import api from '../../services/api'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const uploadAnexos = async (anexos: File[], relatId: number) => {
  const path = `/relatorios/${relatId}/anexos`
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  const formData = new FormData()
  anexos.forEach(f => formData.append('anexos', f))
  let data
  try {
    const resp = await api.post(path, formData, config)
    data = resp.data
  } catch (error) {
    console.log(error)
    return []
  }
  return data as Anexo[]
}

const Anexos = () => {
  const { relatorio, dispatch, isLoading } = useContext(Context)

  const [currentFiles, setCurrentFiles] = useState<File[]>([])
  if (isLoading) return <></>

  const handleFileDrop = async (droppedFiles: File[]) => {
    const uploaded = await uploadAnexos(droppedFiles, relatorio.id || -1)
    setCurrentFiles([...droppedFiles])
    dispatch({ type: ACTIONS.ANEXO_ADD, payload: { anexos: uploaded } })
  }

  const onClearClick = (fileName: string) => {
    setCurrentFiles(currentFiles.filter(d => d.name !== fileName))
    dispatch({ type: ACTIONS.ANEXO_DELETE, payload: { id: fileName } })
  }

  if (isLoading) return <></>

  const arquivosSalvosText = `${relatorio?.anexos?.length} Arquivo${
    relatorio?.anexos?.length !== 1 ? 's' : ''
  } Salvo${relatorio?.anexos?.length !== 1 ? 's' : ''}`

  return (
    <Stack hasGutter>
      <StackItem>
        <TextContent>
          <Text component={TextVariants.h2}>Anexos</Text>
        </TextContent>
      </StackItem>
      <StackItem>
        <MultipleFileUpload onFileDrop={handleFileDrop}>
          <Card>
            <CardBody>
              <Split hasGutter>
                <SplitItem isFilled>
                  <Stack hasGutter>
                    <StackItem>
                      <MultipleFileUploadMain
                        titleIcon={<UploadIcon />}
                        titleText='Arraste arquivos aqui'
                        titleTextSeparator='ou'
                      />
                    </StackItem>
                  </Stack>
                </SplitItem>
                <SplitItem isFilled>
                  <Stack>
                    <TextContent>
                      <Text component={TextVariants.h4}>{arquivosSalvosText}</Text>
                    </TextContent>
                    {relatorio.anexos?.map((a, i) => (
                      <StackItem key={i}>
                        <Label
                          variant='outline'
                          icon={<FileIcon />}
                          onClose={() => onClearClick(a.original_filename)}
                          className='pf-u-mt-sm'
                        >
                          {a.original_filename}
                        </Label>
                      </StackItem>
                    ))}
                  </Stack>
                </SplitItem>
              </Split>
            </CardBody>
            <CardFooter>
              <MultipleFileUploadStatus statusToggleText='Arquivos Enviados'>
                {currentFiles.map((file, i) => (
                  <MultipleFileUploadStatusItem
                    file={file}
                    key={i}
                    onClearClick={() => onClearClick(file.name)}
                  />
                ))}
              </MultipleFileUploadStatus>
            </CardFooter>
          </Card>
        </MultipleFileUpload>
      </StackItem>
    </Stack>
  )
}

export default Anexos
