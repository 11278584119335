import { Grid, GridItem } from '@patternfly/react-core'
import { useContext } from 'react'
import PageFrame from '../shared/PageFrame'
import GlobalContext from '../../contexts/GlobalContext'
import TableClientes from './TableClientes'
import TableUsers from './TableUsers'

const Dashboard = () => {
  const { isLoading } = useContext(GlobalContext)
  return (
    <PageFrame
      pageName='Painel do Administrador'
      isLoading={isLoading}
    >
      <Grid hasGutter>
        <GridItem xl={1} />
        <GridItem sm={12} md={12} lg={12} xl={10}>
          <TableClientes />
          <TableUsers />
        </GridItem>
        <GridItem xl={1} />
      </Grid>
    </PageFrame>
  )
}

export default Dashboard
