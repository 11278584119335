import { Checkbox } from '@patternfly/react-core'
import { useContext, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import ModalDelete from '../shared/ModalDelete'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const ApoioRemoto = () => {
  const { relatorio, dispatch, isLoading } = useContext(Context)
  const { precos } = useContext(GlobalContext)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  if (isLoading) return <></>

  const handleChange = () => {
    const tabelaPrecos = precos.get(relatorio.tabela_precos_id || -1)
    dispatch({ type: ACTIONS.APOIO_REMOTO_TOGGLE, payload: { tabelaPrecos } })
  }

  return (
    <>
      <Checkbox
        label='Apoio Remoto'
        isChecked={relatorio.apoio_remoto}
        onChange={() => setIsModalDeleteOpen(!isModalDeleteOpen)}
        id='apoio-remoto-check-1'
        name='apoio-remoto'
        className='pf-u-m-md pf-u-my-md-on-md'
      />
      <ModalDelete
        title={relatorio.apoio_remoto ? 'Desabilitar' : 'Habilitar Apoio Remoto'}
        isModalOpen={isModalDeleteOpen}
        handleModalToggle={() => setIsModalDeleteOpen(!isModalDeleteOpen)}
        handleSubmit={handleChange}
        alertMessage='Habilitar/desabilitar apoio remoto causará alterações no valor do relatório. Além da exclusão do "local de atendimento"'
        removeButtonLabel={relatorio.apoio_remoto ? 'Desabilitar' : 'Habilitar'}
      />
    </>
  )
}

export default ApoioRemoto
