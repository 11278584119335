import { debounce, InputGroup, InputGroupText, TextInput } from '@patternfly/react-core'
import { OutlinedCalendarAltIcon } from '@patternfly/react-icons'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import useMobile from '../../hooks/useMobile'

const datetimePattern = /[1-2]0[0-4][0-9]\/[0-1][0-9]\/[0-3][0-9] [0-2][0-9]:[0-5][0-9]/g
const datePattern = /[1-2]0[0-4][0-9]\/[0-1][0-9]\/[0-3][0-9]/g

interface IProp {
  value: Date
  onChange: (newDate: Date) => void
  onToggleCalendar?: () => void
  variant?: 'date' | 'datetime'
  showCalendarIcon?: boolean
  onClickText?: () => void
  validate?: (newDate: Date) => boolean
}

const CalendarTextInput = ({
  value,
  onChange,
  onToggleCalendar,
  onClickText,
  showCalendarIcon = true,
  variant = 'date',
  validate,
}: IProp) => {
  const dt = DateTime.fromJSDate(value)
  const dtPattern = { date: datePattern, datetime: datetimePattern }[variant]
  const datetimeFormat = variant === 'date' ? 'yyyy/MM/dd' : 'yyyy/MM/dd HH:mm'
  const [innerValue, setInnerValue] = useState<string>(dt.toFormat(datetimeFormat))
  const isMobile = useMobile()
  const [validated, setValidated] = useState<'success' | 'warning' | 'error' | 'default'>('default')

  const debounceDelay = 500
  const debounceOnChange = debounce(onChange, debounceDelay)

  const validateTextInput = (newText: string) => {
    const newDate = new Date(newText)
    if (!Number.isNaN(newDate.getDay()) && !!newText.match(dtPattern)) {
      return newDate
    }
    return false
  }

  const handleTextInput = (newText: string) => {
    setInnerValue(newText)
    const validDate = validateTextInput(newText)
    if (!validDate) {
      setValidated('error')
      return
    }
    if (validate && !validate(validDate)) {
      setValidated('warning')
      return
    }
    setValidated('default')
    debounceOnChange(validDate)
  }

  useEffect(() => {
    setInnerValue(DateTime.fromJSDate(value).toFormat(datetimeFormat))
    setValidated('default')
  }, [value])

  return (
    <InputGroup className='pf-u-my-sm'>
      <TextInput
        aria-label='datetime-input'
        validated={validated}
        value={innerValue}
        onChange={handleTextInput}
        type='text'
        style={
          isMobile ? { maxWidth: '100%' } : { maxWidth: variant === 'datetime' ? '10rem' : '8rem' }
        }
        onClick={onClickText}
        placeholder={datetimeFormat.toLowerCase()}
        className='pf-u-text-align-center'
      />
      {showCalendarIcon ? (
        <InputGroupText className='icon-calendar-text-input'>
          <OutlinedCalendarAltIcon color='black' onClick={onToggleCalendar} />
        </InputGroupText>
      ) : null}
    </InputGroup>
  )
}

export default CalendarTextInput
