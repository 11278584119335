import { Buffer } from 'buffer'

const TOKEN_KEY = '@relatorToken'
const KEEP_LOGGED_KEY = '@relatorKeepLogged'

interface IJWT {
  uid: string
  exp: number
}

const parseJWT = (jwt_string: string) => {
  const buffer = Buffer.from(jwt_string.split('.')[1], 'base64')
  const jwt = JSON.parse(buffer.toString()) as IJWT
  return jwt
}

const isExpired = (expires: number) => {
  return Math.floor(new Date().getTime() / 1000) >= expires
}

const isAuthenticated = () => {
  return getToken() !== null
}

const getToken = (raw = false) => {
  const keepLogged = localStorage.getItem(KEEP_LOGGED_KEY) === 'true'
  const jwt_string = keepLogged
    ? localStorage.getItem(TOKEN_KEY)
    : sessionStorage.getItem(TOKEN_KEY)
  if (!jwt_string) {
    console.log(`Empty jwt string. keepLogged: ${keepLogged}`)
    return null
  }
  const jwt = parseJWT(jwt_string)
  if (isExpired(jwt.exp)) {
    console.error('Authorization token expired')
    logout()
    return null
  }
  if (raw) return jwt_string
  return jwt
}

const login = (jwt_string: string, keepLogged: boolean) => {
  if (jwt_string === undefined || keepLogged === undefined) return
  localStorage.setItem(KEEP_LOGGED_KEY, String(keepLogged))
  if (keepLogged) {
    localStorage.setItem(TOKEN_KEY, jwt_string)
  } else {
    sessionStorage.setItem(TOKEN_KEY, jwt_string)
  }
}

const logout = () => {
  localStorage.removeItem(KEEP_LOGGED_KEY)
  localStorage.removeItem(TOKEN_KEY)
  sessionStorage.removeItem(TOKEN_KEY)
  window.location.href = '/'
}

export { isAuthenticated, getToken, login, logout }
