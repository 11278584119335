const LOCALE = 'pt-BR'

const brlFormatter = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format

const formatBRL = (value: number) => brlFormatter(value)

const BRLtoInteger = (value: string) => Math.trunc(Number(value) * 100)

const IntegerToBRL = (value: number) => parseFloat(`${value / 100}`)

export { LOCALE, formatBRL, BRLtoInteger, IntegerToBRL }
