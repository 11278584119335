import {
  CalendarMonth as BaseCalendarMonth,
  CalendarProps,
  capitalize,
} from '@patternfly/react-core'

const LOCALE = 'pt-BR'

const customStyle = {
  backgroundColor: 'var(--pf-c-popover__content--BackgroundColor)',
  padding: '1rem 1rem 1rem 0.5rem',
}

interface ICalendarDateProps extends CalendarProps {
  startDate?: Date
  endDate?: Date
  minEnabled?: Date
  maxEnabled?: Date
}

const CalendarDate = ({
  date,
  onChange,
  style,
  startDate,
  endDate,
  validators,
  minEnabled,
  maxEnabled,
  ...props
}: ICalendarDateProps) => {
  const disablePreStartDates = (date: Date) => {
    if (!!minEnabled && date < minEnabled) return false
    if (!!maxEnabled && date > maxEnabled) return false

    if (endDate) return date <= endDate
    if (startDate) return date >= startDate

    return true
  }

  const _validators = validators ? [...validators] : []

  return (
    <BaseCalendarMonth
      date={date}
      style={{ ...customStyle, ...style }}
      locale={LOCALE}
      monthFormat={date => capitalize(date.toLocaleDateString(LOCALE, { month: 'long' }))}
      onChange={onChange}
      validators={[disablePreStartDates, ..._validators]}
      {...props}
    />
  )
}

export default CalendarDate
