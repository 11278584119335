import { InputGroup, InputGroupText, TextInput } from '@patternfly/react-core'
import { useEffect, useState } from 'react'
import { IntegerToBRL } from '../../utils/currency'

const monetaryRegex = /(^\d+$)|(^\d+,\d{2}$)/g

interface IProp {
  className?: string
  value: number
  onChange: (num: number) => void
}

const MonetaryInput = ({ value, onChange, className }: IProp) => {
  const [innerValue, setInnerValue] = useState<string>(
    IntegerToBRL(value).toFixed(2).replace('.', ',')
  )
  const [isValid, setIsValid] = useState(true)

  const handleChange = (inputValue: string) => {
    if (inputValue === '') {
      setIsValid(true)
      setInnerValue(inputValue)
      return
    }
    const found = inputValue.match(monetaryRegex)
    if (!found || found.length === 0) {
      setIsValid(false)
      setInnerValue(inputValue)
      return
    }
    setIsValid(true)
    setInnerValue(inputValue)
  }
  useEffect(() => {
    if (!isValid) return
    const num = Number(innerValue.replace(',', '.')) * 100
    onChange(num)
  }, [isValid, innerValue])

  return (
    <InputGroup className={`pf-u-mx-sm ${className}`}>
      <InputGroupText>R$</InputGroupText>
      <TextInput
        aria-label='number-input'
        validated={isValid ? 'default' : 'error'}
        value={innerValue}
        onChange={inputValue => handleChange(inputValue)}
        type='text'
      />
    </InputGroup>
  )
}

export default MonetaryInput
