import {
  Grid,
  GridItem,
  InputGroup,
  Split,
  SplitItem,
  Stack,
  StackItem,
  TextInput,
} from '@patternfly/react-core'
import { css } from '@patternfly/react-styles'
import { useEffect, useState } from 'react'
import months from '../../utils/months'
import Arrow from '../Arrow'

interface ICalendarMonthProps {
  date: Date
  onChange?: (newDate: Date) => void
  className?: string
  endDate?: Date
  startDate?: Date
  minEnabled?: Date
  maxEnabled?: Date
  validators?: ((date: Date) => boolean)[]
}

const CalendarMonth = ({
  date,
  onChange,
  className = '',
  startDate,
  endDate,
  minEnabled,
  maxEnabled,
  validators,
}: ICalendarMonthProps) => {
  const [isValid, setIsValid] = useState(true)
  const [year, setYear] = useState(date.getFullYear())
  const [yearText, setYearText] = useState('')
  // Atenção Date.getMonth() retorna janeiro como 0, vide:
  // https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
  const [monthYear, setMonthYear] = useState([date.getMonth(), date.getFullYear()])

  useEffect(() => {
    setYearText(year.toString())
  }, [year])

  useEffect(() => {
    if (!onChange) return
    const day = date.getDate()
    const newDate = new Date(year, monthYear[0], day)
    const oldDate = new Date(date.getFullYear(), date.getMonth(), day)
    if (oldDate.getTime() === newDate.getTime()) return
    onChange(newDate)
  }, [monthYear])

  return (
    <div className={`pf-u-p-sm ${className}`}>
      <Stack style={{ width: '15rem' }} className='pf-u-text-align-center'>
        <StackItem>
          <Split hasGutter>
            <SplitItem className='pf-u-pl-lg'>
              <Arrow
                variant='plain'
                direction='left'
                onClick={() => {
                  setYear(year - 1)
                  setIsValid(true)
                }}
              />
            </SplitItem>
            <SplitItem isFilled>
              <InputGroup className='pf-u-my-sm pf-u-mx-sm'>
                <TextInput
                  validated={isValid ? 'default' : 'error'}
                  className=' pf-u-text-align-center'
                  aria-label='text-input-year-month-calendar'
                  value={yearText}
                  onChange={(newYear: string) => {
                    setYearText(newYear)
                    const yearParsed = parseInt(newYear)
                    if (Number.isNaN(yearParsed) || yearParsed < 1900 || yearParsed > 2100) {
                      setIsValid(false)
                      return
                    }
                    setIsValid(true)
                    setYear(yearParsed)
                  }}
                />
              </InputGroup>
            </SplitItem>
            <SplitItem className='pf-u-pr-lg'>
              <Arrow
                variant='plain'
                direction='right'
                onClick={() => {
                  setYear(year + 1)
                  setIsValid(true)
                }}
              />
            </SplitItem>
          </Split>
        </StackItem>
        <StackItem>
          <Grid span={4}>
            {months.map((m, i) => {
              const externalDate = new Date(date.getFullYear(), monthYear[0], 1)
              const internalDate = new Date(year, i, 1)

              let isCurrentMonth = false
              let isEnabled = true
              if (externalDate.getTime() === internalDate.getTime()) {
                isCurrentMonth = true
              }
              if (
                (!!endDate && internalDate > endDate) ||
                (!!startDate && internalDate < startDate) ||
                (!!minEnabled && internalDate < minEnabled) ||
                (!!maxEnabled && internalDate > maxEnabled)
              ) {
                isEnabled = false
              }

              if (isEnabled && validators) {
                validators.forEach(validator => {
                  if (!validator(internalDate)) isEnabled = false
                })
              }

              return (
                <GridItem sm={4} key={m}>
                  <div
                    onClick={() => {
                      if (isEnabled) setMonthYear([i, year])
                    }}
                    className={css(
                      'pf-u-py-md',
                      'datetime-calendar-month',
                      isCurrentMonth && 'datetime-calendar-month-selected',
                      !isEnabled && 'datetime-calendar-month-disabled'
                    )}
                    style={{ margin: '2px' }}
                  >
                    {m}
                  </div>
                </GridItem>
              )
            })}
          </Grid>
        </StackItem>
      </Stack>
    </div>
  )
}

export default CalendarMonth
