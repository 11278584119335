import { Bullseye, Spinner } from '@patternfly/react-core'

const SkeletonPage = () => {
  return (
    <Bullseye>
      <Spinner isSVG size='xl' aria-label='Loading' />
    </Bullseye>
  )
}

export default SkeletonPage
