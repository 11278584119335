import { Alert } from '@patternfly/react-core'

const ValidationAlert = ({
  message,
  variant,
}: {
  message: string
  variant?: 'default' | 'danger' | 'success' | 'warning' | 'info'
}) => {
  const _variant = variant || 'danger'
  return (
    <Alert
      id={`${message}_${_variant}`}
      title={message}
      className='pf-u-mb-lg'
      variant={_variant}
      isInline
    />
  )
}

export default ValidationAlert
