import { Alert, Button, Modal, ModalVariant } from '@patternfly/react-core'
import { ExclamationCircleIcon, TrashIcon } from '@patternfly/react-icons'
import { useEffect } from 'react'

const AlertModal = ({ deleteSuccess }: { deleteSuccess?: boolean }) => {
  const variant = deleteSuccess ? 'success' : 'danger'
  const message = deleteSuccess ? 'Item removido com sucesso' : 'Ocorreu um erro ao deletar'

  return <Alert className='pf-u-mb-lg' variant={variant} isInline title={message} />
}

const ModalDelete = ({
  title,
  isModalOpen,
  handleModalToggle,
  handleSubmit,
  element,
  deleteSuccess,
  alertMessage = 'Tem certeza que deseja remover este item? Essa ação não poderá ser desfeita.',
  removeButtonLabel = 'Remover',
  cancelButtonLabel = 'Cancelar',
}: {
  title: string
  isModalOpen: boolean
  handleModalToggle: () => void
  handleSubmit: (element: any) => void
  element?: any
  deleteSuccess?: boolean
  alertMessage?: string
  removeButtonLabel?: string
  cancelButtonLabel?: string
}) => {
  useEffect(() => {
    if (deleteSuccess) {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [deleteSuccess])

  return (
    <Modal
      variant={ModalVariant.small}
      title={title}
      aria-label='modal delete'
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button
          key='delete'
          variant='danger'
          isActive={false}
          onClick={() => {
            handleSubmit(element)
            if (deleteSuccess === undefined) handleModalToggle()
          }}
        >
          <ExclamationCircleIcon /> {removeButtonLabel} <TrashIcon />
        </Button>,
        <Button key='cancelar' variant='primary' onClick={handleModalToggle}>
          {cancelButtonLabel}
        </Button>,
      ]}
    >
      {deleteSuccess && <AlertModal deleteSuccess={deleteSuccess} />}
      {alertMessage}
    </Modal>
  )
}

export default ModalDelete
