import { Button, Modal, ModalVariant, Split, SplitItem } from '@patternfly/react-core'
import { ReactElement } from 'react'

const ModalCalendar = ({
  onConfirm,
  isModalOpen,
  children,
  toggleModal,
}: {
  toggleModal?: () => void
  isModalOpen: boolean
  children: ReactElement
  onConfirm?: () => void
}) => {
  return (
    <Modal
      showClose={false}
      aria-label='ModalCalendar'
      onClose={toggleModal}
      variant={ModalVariant.large}
      actions={[
        <Button key='confirm' variant='primary' onClick={onConfirm}>
          Fechar
        </Button>,
      ]}
      isOpen={isModalOpen}
    >
      <Split>
        <SplitItem isFilled></SplitItem>
        <SplitItem>{children}</SplitItem>
        <SplitItem isFilled></SplitItem>
      </Split>
    </Modal>
  )
}

export default ModalCalendar
