import { Button, InputGroup, InputGroupText } from '@patternfly/react-core'
import { OutlinedCalendarAltIcon } from '@patternfly/react-icons'
import { css } from '@patternfly/react-styles'
import { brlMonths } from '../../utils/months'

const MonthTextInput = ({
  date,
  className,
  showCalendarIcon = false,
  onClick,
}: {
  date: Date
  className?: string
  showCalendarIcon?: boolean
  onClick?: () => void
}) => {
  return (
    <InputGroup className={css('pf-u-my-sm', 'pf-u-w-100-on-sm', className)}>
      <Button
        className='pf-u-text-align-left pf-u-text-align-center-on-md'
        style={{ width: '100%', minWidth: '9rem' }}
        variant='control'
        onClick={onClick}
      >
        {brlMonths[date.getMonth()]} {date.getFullYear()}
      </Button>
      {showCalendarIcon && (
        <InputGroupText className='icon-calendar-text-input'>
          <OutlinedCalendarAltIcon color='black' />
        </InputGroupText>
      )}
    </InputGroup>
  )
}

export default MonthTextInput
