import { Select as CoreSelect, SelectOption, Button, Spinner } from '@patternfly/react-core'
import { useState } from 'react'

const Select = (props) => {
  const { list, startSelected, onChange, isLoading, maxHeight, direction } = props
  const [isOpen, setOpen] = useState(false)
  const handleToggle = (toggled) => {
    setOpen(toggled)
  }

  const handleSelect = (_, selection) => {
    if (onChange) onChange(selection)
    setOpen(false)
  }

  if (!list || list.length === 0 || isLoading) {
    return (
      <Button variant='control' className='pf-u-m-sm'>
        <Spinner className='pf-u-ml-lg pf-u-mr-lg' isSVG size='md' aria-label='Loading' />
      </Button>
    )
  }

  return (
    <div className='pf-u-p-sm'>
      <CoreSelect
        direction={direction}
        id={`select_${JSON.stringify(list.map(i => i.label))}`}
        position='right'
        isOpen={isOpen}
        onSelect={handleSelect}
        selections={startSelected}
        onToggle={(e) => handleToggle(e)}
        maxHeight={maxHeight || 600}
        menuAppendTo='parent'
      >
        {list.map((item, index) => {
          return (
            <SelectOption
              inputId={`sel_opt_${item.label}_${item.value}_inp_id`}
              id={`sel_opt_${item.label}_${item.value}`}
              key={index}
              value={item.value}
            >
              {' '}
              {item.label}{' '}
            </SelectOption>
          )
        })}
      </CoreSelect>
    </div>
  )
}

export default Select
