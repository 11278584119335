import UAParser from 'ua-parser-js'

const useMobile = () => {
  const parser = new UAParser(window.navigator.userAgent)
  const device = parser.getDevice()
  const isMobile = device.type === 'mobile'

  return isMobile
}

export default useMobile
