import { Select, SelectOption, SelectVariant } from '@patternfly/react-core'
import { useContext, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Context from '../store/Context'
import { ACTIONS } from '../store/reducer'

const SelectTabelaPrecos = () => {
  const { isLoading, dispatch, relatorio } = useContext(Context)
  const { precos } = useContext(GlobalContext)
  const [isOpen, setIsOpen] = useState(false)

  if (isLoading) return <></>

  const tabPrecos = precos.get(relatorio?.tabela_precos_id || -1)

  return (
    <Select
      variant={SelectVariant.single}
      onToggle={() => setIsOpen(!isOpen)}
      onSelect={() => setIsOpen(false)}
      selections={tabPrecos?.nome}
      isOpen={isOpen}
      placeholderText='Tabela de Preços'
      aria-labelledby='select_tabela_preco'
      ouiaId={'InnerTabelaDePrecosSelectionOuiaId'}
      toggleId={'InnerTabelaDePrecosSelectionToggleId'}
    >
      {Array.from(precos).map(p => (
        <SelectOption
          key={p[0]}
          value={p[1].nome}
          onClick={() =>
            dispatch({ type: ACTIONS.SWITCH_TABELA_PRECOS, payload: { tabelaPrecos: p[1] } })
          }
        />
      ))}
    </Select>
  )
}

export default SelectTabelaPrecos
