import {
  Alert,
  Brand,
  Button,
  Masthead,
  MastheadBrand,
  MastheadMain,
  Nav,
  NavItem,
  NavList,
  Page,
  PageSection,
  Split,
  SplitItem,
} from '@patternfly/react-core'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import ModalSessionExpired from './ModalSessionExpired'
import SkeletonPage from './SkeletonPage'

import imgBrand from '../../../assets/img/logo-nav.png'
import { getToken, logout } from '../../../services/auth'

import GlobalContext from '../../../contexts/GlobalContext'

const { REACT_APP_ENVIRONMENT } = process.env

const TopNav = () => {
  const { pathname } = useLocation()
  const { me, meIsLoading } = useContext(GlobalContext)
  const path = pathname.replaceAll('/', '')
  if (meIsLoading || !me) return <></>

  const { authorized } = me

  return (
    <Nav variant='horizontal' aria-label='Nav'>
      <NavList style={{ textTransform: 'capitalize' }}>
        {authorized.map(page => (
          <NavItem itemId={page} key={page} isActive={path === page}>
            <Link to={`/${page}`}>{page}</Link>
          </NavItem>
        ))}
      </NavList>
    </Nav>
  )
}

const MainHeader = () => {
  const [isModalSessionOpen, setModalSessionOpen] = useState(false)
  const token = getToken(true)

  useEffect(() => {
    if (!token || typeof token === 'string') return
    const { exp } = token
    const expiry = new Date(exp * 1000)
    const dtNow = new Date()
    const timeout = expiry.getTime() - dtNow.getTime() - 1000 * 60 * 5
    console.log('Session expiry', expiry)
    const timer = setTimeout(() => {
      setModalSessionOpen(true)
    }, timeout)
    return () => clearTimeout(timer)
  }, [token])

  const handleLogOut = () => {
    logout()
    window.location.href = '/'
  }

  return (
    <Masthead>
      <MastheadMain>
        <MastheadBrand href='/home' style={{ height: '2rem' }}>
          <Brand src={imgBrand} alt='Relator' />
        </MastheadBrand>
      </MastheadMain>
      <Split>
        <SplitItem>
          <TopNav />
        </SplitItem>
        <SplitItem isFilled />
        <SplitItem>
          <Button className='pf-u-mr-lg' isInline onClick={handleLogOut}>
            Logoff
          </Button>
        </SplitItem>
      </Split>
      <ModalSessionExpired isModalOpen={isModalSessionOpen} />
    </Masthead>
  )
}

const PageFrame = ({
  pageName,
  children,
  isLoading,
}: {
  pageName: string
  children: ReactNode
  isLoading: boolean
}) => {
  useEffect(() => {
    document.title = `Relator | ${pageName}`
  }, [])

  const content = isLoading ? <SkeletonPage /> : children

  return (
    <Page
      header={<MainHeader />}
      isTertiaryNavWidthLimited
      isBreadcrumbWidthLimited
      isTertiaryNavGrouped
      isBreadcrumbGrouped
    >
      {REACT_APP_ENVIRONMENT === 'HOMOLOG' && (
        <Alert
          className='pf-u-py-lg'
          variant='danger'
          truncateTitle={3}
          title='MODO DE TESTE ATIVO - Nenhum email será enviado pra cliente'
        />
      )}
      <PageSection style={{ minHeight: 'calc(100vh - 4.4rem)' }}>{content}</PageSection>
    </Page>
  )
}

export default PageFrame
