import { Modal, Split, SplitItem, Text, TextContent, TextVariants } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useContext } from 'react'
import api from '../../services/api'
import Context from './store/Context'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 5,
}

interface IPropType {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ModalVisualizar = ({ isModalOpen, setIsModalOpen }: IPropType) => {
  const { isLoading, relatorio } = useContext(Context)

  const reviewEnabled = !!relatorio.relat_revisao_id

  const { isLoading: renderIsLoading, data: render } = useQuery({
    queryKey: ['render'],
    queryFn: () => api.get(`relatorios/${relatorio.id}/render`).then(r => r.data),
    enabled: !isLoading && !!relatorio.id,
    ...queryOptions,
  })

  const { isLoading: renderReviewIsLoading, data: renderReview } = useQuery({
    queryKey: ['renderReview'],
    queryFn: () => api.get(`relatorios/${relatorio.relat_revisao_id}/render`).then(r => r.data),
    enabled: !isLoading && reviewEnabled,
    ...queryOptions,
  })

  if (isLoading || renderIsLoading) return <></>

  return (
    <Modal
      onClose={() => setIsModalOpen(false)}
      title='Visualizar'
      aria-label='modal-visualizar'
      isOpen={isModalOpen}
    >
      <Split hasGutter>
        <SplitItem isFilled>
          <TextContent>
            <Text component={TextVariants.h2}>
              {reviewEnabled && !renderReviewIsLoading ? 'Original' : ''}
            </Text>
            <Text>--</Text>
          </TextContent>
          <div dangerouslySetInnerHTML={{ __html: render }} />
        </SplitItem>
        {reviewEnabled && !renderReviewIsLoading ? (
          <SplitItem isFilled>
            <TextContent>
              <Text component={TextVariants.h2}>Revisado</Text>
              <Text>--</Text>
            </TextContent>
            <div dangerouslySetInnerHTML={{ __html: renderReview }} />
          </SplitItem>
        ) : (
          <></>
        )}
      </Split>
    </Modal>
  )
}

export default ModalVisualizar
