import { Popover, PopoverPosition, Split, SplitItem } from '@patternfly/react-core'
import { useState } from 'react'
import useMobile from '../../hooks/useMobile'

import CalendarDate from './CalendarDate'
import CalendarDatetime from './CalendarDatetime'
import CalendarTextInput from './CalendarTextInput'
import ModalCalendar from './ModalCalendar'

interface IDatetimePickerProps {
  className?: string
  value: Date
  onChange: (dt: Date) => void
  variant: 'date' | 'datetime'
}

const DatetimePicker = ({ value, onChange, className, variant = 'date' }: IDatetimePickerProps) => {
  const isMobile = useMobile()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const bodyContent = (
    <Split>
      <SplitItem>
        {variant === 'datetime' ? (
          <CalendarDatetime date={value} onChange={onChange} />
        ) : (
          <CalendarDate date={value} onChange={onChange} />
        )}
      </SplitItem>
    </Split>
  )
  const textInput = (
    <div className={className}>
      <CalendarTextInput
        value={value}
        onChange={onChange}
        variant={variant}
        onToggleCalendar={toggleModal}
      />
    </div>
  )
  return (
    <>
      {isMobile ? (
        <>
          {textInput}
          <ModalCalendar isModalOpen={isModalOpen} toggleModal={toggleModal}>
            {bodyContent}
          </ModalCalendar>
        </>
      ) : (
        <Popover
          aria-label='DateTimeInputPopOver'
          position={PopoverPosition.bottomEnd}
          enableFlip
          flipBehavior={['bottom-end', 'bottom-start', 'top-end', 'top-start']}
          hideOnOutsideClick
          hasAutoWidth
          hasNoPadding
          withFocusTrap={false}
          showClose={false}
          bodyContent={bodyContent}
        >
          {textInput}
        </Popover>
      )}
    </>
  )
}

export default DatetimePicker
