import { TextInput } from '@patternfly/react-core'
import { useContext } from 'react'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const SelectLocal = () => {
  const { isLoading, relatorio, dispatch } = useContext(Context)

  if (isLoading) return <></>

  const onChange = (value: string) => {
    dispatch({
      type: ACTIONS.UPDATE_PROP,
      payload: { prop: { name: 'local_atendimento', value } },
    })
  }

  return (
    <TextInput
      placeholder='Local de atendimento (Opcional)'
      value={!!relatorio.local_atendimento ? relatorio.local_atendimento : ''}
      type='text'
      aria-label='tempo_cobravel_justificativa'
      onChange={onChange}
      isDisabled={relatorio.apoio_remoto}
      className='pf-u-m-md pf-u-my-sm-on-md'
    />
  )
}

export default SelectLocal
