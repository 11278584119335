import { Select, SelectOption, SelectVariant } from '@patternfly/react-core'
import { useContext, useState } from 'react'
import { Cliente } from '../../clients'
import GlobalContext from '../../contexts/GlobalContext'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const SelectCliente = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isLoading, dispatch, relatorio } = useContext(Context)
  const { clientes, precos } = useContext(GlobalContext)

  const handleClienteChange = (cliente_id: number) => {
    const cliente = clientes.get(cliente_id)
    if (!cliente) {
      console.error(`handleClienteChange: Nenhum cliente encontrado para o ID:${cliente_id}`)
      return
    }
    const tabelaPrecos = precos.get(cliente.tabela_precos_id || -1)
    dispatch({
      type: ACTIONS.SWITCH_CLIENT,
      payload: { cliente, tabelaPrecos },
    })
    setIsOpen(false)
  }

  if (isLoading) return <></>

  const cliente = clientes.get(relatorio.cliente_id || -1)

  const clientesTuples = Array.from(clientes)

  const mapSelectOptions = (_clientes: Array<[number, Cliente]>) => {
    return _clientes.map(c => (
      <SelectOption
        key={c[0]}
        value={c[1].nome_exibicao}
        onClick={() => handleClienteChange(c[0])}
      />
    ))
  }

  const onFilter = (_: any, textInput: string) => {
    if (textInput === '') return mapSelectOptions(clientesTuples)

    return mapSelectOptions(
      clientesTuples.filter(
        c =>
          c[1].nome
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              textInput
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            ) ||
          c[1].nome_exibicao
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              textInput
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
      )
    )
  }

  return (
    <Select
      variant={SelectVariant.single}
      onToggle={() => setIsOpen(!isOpen)}
      selections={cliente?.nome_exibicao}
      isOpen={isOpen}
      onSelect={() => setIsOpen(false)}
      placeholderText='Cliente'
      aria-labelledby='Cliente'
      ouiaId={'clienteSelectionOuiaId'}
      toggleId={'clienteSelectionToggleId'}
      onFilter={onFilter}
      hasInlineFilter
      inlineFilterPlaceholderText='Pesquisar'
      noResultsFoundText='Nenhum cliente encontrado'
      className='pf-u-m-md pf-u-my-sm-on-md'
    >
      {mapSelectOptions(clientesTuples)}
    </Select>
  )
}

export default SelectCliente
