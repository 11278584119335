import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { Relatorio } from '../../../clients'
import GlobalContext from '../../../contexts/GlobalContext'
import { ACTIONS, DEFAULT_STATE, IAction, IState, reducer } from './reducer'

type IContext = {
  isLoading: boolean
  relatorio: Relatorio
  handleSaveRelat?: () => void
  dispatch: (value: IAction) => void
}

const contextDefaults: IContext = {
  isLoading: true,
  relatorio: {} as Relatorio,
  dispatch: (value: IAction) => {},
}

const Context = createContext<IContext>(contextDefaults)

export const Provider = ({ children }: { children: ReactNode }) => {
  const { isLoading, relatorios } = useContext(GlobalContext)

  const { relatId } = useParams()

  const [state, dispatch]: [IState, Dispatch<IAction>] = useReducer(reducer, DEFAULT_STATE)
  const { relatorio } = state

  useEffect(() => {
    if (isLoading || !relatId) return
    dispatch({ type: ACTIONS.SET_RELAT, payload: { relatorios, id: parseInt(relatId) } })
  }, [relatId, isLoading])

  const handleSaveRelat = async () => {
    dispatch({ type: ACTIONS.SAVE_RELAT, payload: {} })
  }

  const handleCtrlS = (event: any) => {
    if (event.ctrlKey !== true || !['S', 's'].includes(event.key)) return
    event.preventDefault()
    handleSaveRelat()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleCtrlS)
    return () => {
      document.removeEventListener('keydown', handleCtrlS)
    }
  }, [handleCtrlS])

  const provides: IContext = {
    isLoading,
    relatorio,
    handleSaveRelat,
    dispatch,
  }

  return <Context.Provider value={provides}>{children}</Context.Provider>
}

export default Context
