import {
  Button,
  Card,
  CardBody,
  CardTitle,
  ExpandableSection,
  Modal,
  ModalVariant,
  Spinner,
} from '@patternfly/react-core'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@patternfly/react-icons'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Validation } from '../../clients'
import api, { queryClient } from '../../services/api'
import { timeout } from '../../utils/misc'
import { sendRelat } from './misc'
import Context from './store/Context'

const fetchRelatValidation = async (relat_id: number) => {
  let data
  try {
    const response = await api.get(`/relatorios/${relat_id}/validate`)
    data = response.data
  } catch (error) {
    console.error(error)
  }
  return data as Validation
}

const EnviarParaCliente = () => {
  const { isLoading, relatorio } = useContext(Context)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [validation, setValidation] = useState<Validation>()
  const navigate = useNavigate()

  if (isLoading) return <></>

  const handleOpenModal = async () => {
    if (!relatorio.id) return
    const validation = await fetchRelatValidation(relatorio.id)
    setValidation(validation)
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
    setValidation(undefined)
  }
  const handleSendRelat = async () => {
    if (!relatorio.id) return
    const sendStatus = await sendRelat(relatorio.id)
    console.log(sendStatus)
    setIsSending(true)
    await timeout(2500)
    await queryClient.invalidateQueries(['relatorios'])
    navigate('/')
  }
  const canSend = () => {
    if (!validation || !validation.messages) return false

    return validation.messages.every(m => m.is_valid || (!m.is_valid && m.can_send))
  }
  const sendable = canSend()

  const infoMessages = validation?.messages.filter(m => m.is_valid) || []
  const warningMessages = validation?.messages.filter(m => !m.is_valid && m.can_send) || []
  const erroMessages = validation?.messages.filter(m => !m.is_valid && !m.can_send) || []

  return (
    <>
      <Button variant='primary' onClick={handleOpenModal}>
        Enviar para cliente
      </Button>
      <Modal
        title='Validação'
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        variant={ModalVariant.large}
        actions={[
          <Button
            key={`confirm_${!sendable}`}
            variant='primary'
            onClick={handleSendRelat}
            isDisabled={!sendable}
            isLoading={isSending}
          >
            {isSending ? 'Enviando' : 'Enviar'}
          </Button>,
          <Button key='cancel' variant='link' onClick={handleCloseModal}>
            Voltar
          </Button>,
        ]}
      >
        {sendable ? (
          <p>
            <CheckCircleIcon className='pf-u-mx-sm' color='green' /> Relatório pronto para ser
            enviado para o cliente. Se houverem avisos, o administrador será notificado.
          </p>
        ) : (
          <p>
            <ExclamationTriangleIcon className='pf-u-mx-sm' color='orange' /> Relatório não pode ser
            enviado para o cliente.
          </p>
        )}
        {!validation && (
          <>
            <p>Carregando...</p>
            <Spinner isSVG size='xl' aria-label='validation loading' />
          </>
        )}
        {!!validation &&
          erroMessages.length > 0 &&
          erroMessages.map(m => (
            <Card key={m.name}>
              <CardTitle>
                <ExclamationCircleIcon className='pf-u-mx-sm' color='red' />
                {m.name}
              </CardTitle>
              <CardBody>
                <CardBody>{m.message}</CardBody>
              </CardBody>
            </Card>
          ))}
        {!!validation &&
          warningMessages.length > 0 &&
          warningMessages.map(m => (
            <Card key={m.name}>
              <CardTitle>
                <ExclamationTriangleIcon className='pf-u-mx-sm' color='orange' />
                {m.name}
              </CardTitle>
              <CardBody>
                <CardBody>{m.message}</CardBody>
              </CardBody>
            </Card>
          ))}
        {!!validation && infoMessages.length > 0 && (
          <ExpandableSection toggleText='Validações'>
            {infoMessages.map(m => (
              <Card key={m.name}>
                <CardTitle>
                  <CheckCircleIcon className='pf-u-mx-sm' color='green' />
                  {m.name}
                </CardTitle>
              </Card>
            ))}
          </ExpandableSection>
        )}
      </Modal>
    </>
  )
}

export default EnviarParaCliente
