import { useState } from 'react'
import PageFrame from '../shared/PageFrame'

const NotFound = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  return (
    <PageFrame
      pageName='404'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
    >
      <h1>Página não encontrada</h1>

    </PageFrame>
  )
}

export default NotFound
