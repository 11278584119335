const getTodayMidnight = () => {
  const today = new Date()
  today.setHours(25)
  today.setMinutes(59)
  today.setSeconds(59)
  return today
}

export { getTodayMidnight }

