import { useQuery } from '@tanstack/react-query'
import { createContext, ReactNode } from 'react'
import { Cliente, FormaPagamento, Me, Relatorio, TabelaPrecos, UserOut } from '../clients'
import api from '../services/api'
import { getToken } from '../services/auth'
import { mapFactory } from '../utils/misc'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

type IGlobalContext = {
  isLoading: boolean
  clientesIsLoading: boolean
  precoIsLoading: boolean
  formaPagamentosIsLoading: boolean
  relatoriosIsLoading: boolean
  meIsLoading: boolean
  usuariosIsLoading: boolean
  me: Me
  clientes: Map<number, Cliente>
  precos: Map<number, TabelaPrecos>
  formaPagamentos: Map<number, FormaPagamento>
  usuarios: Map<number, UserOut>
  relatorios: Relatorio[]
}

export type { IGlobalContext }

const globalContextDefaults: IGlobalContext = {
  isLoading: true,
  clientesIsLoading: true,
  precoIsLoading: true,
  formaPagamentosIsLoading: true,
  relatoriosIsLoading: true,
  meIsLoading: true,
  usuariosIsLoading: true,
  me: {} as Me,
  clientes: new Map<number, Cliente>(),
  precos: new Map<number, TabelaPrecos>(),
  formaPagamentos: new Map<number, FormaPagamento>(),
  usuarios: new Map<number, UserOut>(),
  relatorios: [],
}

interface IContextProps {
  children: ReactNode
}

const GlobalContext = createContext<IGlobalContext>(globalContextDefaults)

export const GlobalProvider = ({ children }: IContextProps) => {
  const token = getToken()

  const { isLoading: meIsLoading, data: me } = useQuery({
    queryKey: ['me'],
    queryFn: () => api.get('me').then(r => r.data),
    enabled: !!token,
    ...queryOptions,
  })

  const { isLoading: clientesIsLoading, data: clientes } = useQuery({
    queryKey: ['clientes'],
    queryFn: () => api.get('clientes').then(r => mapFactory<Cliente>(r.data)),
    enabled: !!me,
    ...queryOptions,
  })

  const { isLoading: usuariosIsLoading, data: usuarios } = useQuery({
    queryKey: ['usuarios'],
    queryFn: () => api.get('usuarios').then(r => mapFactory<UserOut>(r.data)),
    enabled: !!me,
    ...queryOptions,
  })

  const { isLoading: precoIsLoading, data: precos } = useQuery({
    queryKey: ['tabelas-preco'],
    queryFn: () => api.get('tabelas-preco').then(r => mapFactory<TabelaPrecos>(r.data)),
    enabled: !!me,
    ...queryOptions,
  })

  const { isLoading: formaPagamentosIsLoading, data: formaPagamentos } = useQuery({
    queryKey: ['formas-pagamento'],
    queryFn: () => api.get('formas-pagamento').then(r => mapFactory<FormaPagamento>(r.data)),
    enabled: !!me,
    ...queryOptions,
  })

  const { isLoading: relatoriosIsLoading, data: relatorios } = useQuery({
    queryKey: ['relatorios'],
    queryFn: () => api.get('relatorios').then(r => r.data),
    enabled: !!me,
    ...queryOptions,
  })

  const isLoading = [
    clientesIsLoading,
    precoIsLoading,
    formaPagamentosIsLoading,
    relatoriosIsLoading,
    meIsLoading,
  ].some(l => l)

  const provides: IGlobalContext = {
    isLoading,
    clientesIsLoading,
    precoIsLoading,
    formaPagamentosIsLoading,
    relatoriosIsLoading,
    meIsLoading,
    usuariosIsLoading,
    me,
    clientes: clientes ? clientes : globalContextDefaults.clientes,
    precos: precos ? precos : globalContextDefaults.precos,
    formaPagamentos: formaPagamentos ? formaPagamentos : globalContextDefaults.formaPagamentos,
    usuarios: usuarios ? usuarios : globalContextDefaults.usuarios,
    relatorios,
  }

  return <GlobalContext.Provider value={provides}>{children}</GlobalContext.Provider>
}

export default GlobalContext
