import { Text, TextArea, TextContent, TextVariants } from '@patternfly/react-core'
import { useContext } from 'react'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const MotivoAtendimento = () => {
  const { isLoading, relatorio, dispatch } = useContext(Context)

  if (isLoading) return <></>

  const onChange = (value: string) => {
    dispatch({
      type: ACTIONS.UPDATE_PROP,
      payload: { prop: { name: 'motivo_atendimento', value } },
    })
  }

  return (
    <>
      <TextContent>
        <Text component={TextVariants.h2}>Motivo do Atendimento</Text>
      </TextContent>
      <TextArea
        placeholder='Digite o Motivo'
        value={!!relatorio.motivo_atendimento ? relatorio.motivo_atendimento : ''}
        validated={
          !relatorio.motivo_atendimento?.length || relatorio.motivo_atendimento.length > 400
            ? 'error'
            : 'default'
        }
        onChange={onChange}
        aria-label='motivo_atendimento'
        resizeOrientation="vertical"
        autoResize
      />
    </>
  )
}

export default MotivoAtendimento
