import { Split, SplitItem, Stack, StackItem, TextInput } from '@patternfly/react-core'
import { useContext } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const Contato = () => {
  const { dispatch, isLoading, relatorio } = useContext(Context)
  const { me } = useContext(GlobalContext)

  if (isLoading) return <></>

  const handleChangeFrom = (name: string, value: string) => {
    if (!me.current_user.isAdmin) return
    dispatch({ type: ACTIONS.UPDATE_PROP, payload: { prop: { name, value } } })
  }

  return (
    <Stack hasGutter>
      <StackItem>
        <Split hasGutter>
          <SplitItem style={{ minWidth: '3rem' }}>From:</SplitItem>
          <SplitItem isFilled>
            <TextInput
              placeholder='tecnico@drsys.com.br'
              value={relatorio.email_from || ''}
              type='text'
              aria-label='email-tec-from'
              onChange={value => handleChangeFrom('email_from', value)}
              isDisabled={!me.current_user.isAdmin}
            />
          </SplitItem>
        </Split>
      </StackItem>
      <StackItem>
        <Split hasGutter>
          <SplitItem style={{ minWidth: '3rem' }}>To:</SplitItem>
          <SplitItem isFilled>
            <TextInput
              value={relatorio.email_to || ''}
              type='text'
              aria-label='email-cliente-to'
              onChange={value => handleChangeFrom('email_to', value)}
            />
          </SplitItem>
        </Split>
      </StackItem>
      <StackItem>
        <Split hasGutter>
          <SplitItem style={{ minWidth: '3rem' }}>CC:</SplitItem>
          <SplitItem isFilled>
            <TextInput
              value={relatorio.email_cc || ''}
              type='text'
              aria-label='email-cliente-cc'
              onChange={value => handleChangeFrom('email_cc', value)}
            />
          </SplitItem>
        </Split>
      </StackItem>
    </Stack>
  )
}

export default Contato
