import React from 'react'
import '@patternfly/patternfly/patternfly.min.css'
import '@patternfly/patternfly/patternfly-addons.css'
import './App.css'
import './assets/css/styles.css'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './Routes'
import { GlobalProvider } from './contexts/GlobalContext'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './services/api'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const { REACT_APP_ENVIRONMENT } = process.env

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalProvider>
          <AppRouter />
          {REACT_APP_ENVIRONMENT === 'dev' && <ReactQueryDevtools />}
        </GlobalProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
