import { Alert, Button, Split, SplitItem } from '@patternfly/react-core'
import { CheckIcon, OutlinedCalendarAltIcon } from '@patternfly/react-icons'

import { useState } from 'react'
import useMobile from '../../hooks/useMobile'
import CalendarDatetimeRange from './CalendarDatetimeRange'
import CalendarTextInput from './CalendarTextInput'
import ModalCalendar from './ModalCalendar'
import MonthTextInput from './MonthTextInput'

interface IDatetimeRangePickerProps {
  startDate: Date
  endDate: Date
  minEnabled?: Date
  maxEnabled?: Date
  onChange: (newDateStart: Date, newDateEnd: Date) => void
  startLabel?: string
  endLabel?: string
  variant?: 'arrows' | 'label'
  calendarVariant?: 'datetime' | 'date' | 'month'
  validators?: ((date: Date) => boolean)[]
}

const DatetimeRangePicker = ({
  startDate,
  endDate,
  onChange,
  startLabel = 'Inicio',
  endLabel = 'Fim',
  variant = 'label',
  calendarVariant = 'datetime',
  minEnabled,
  maxEnabled,
  validators,
}: IDatetimeRangePickerProps) => {
  const [innerStartDate, setInnerStartDate] = useState(startDate)
  const [innerEndDate, setInnerEndDate] = useState(endDate)
  const [isVisible, setIsVisible] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const isMobile = useMobile()

  const handleConfirmar = () => {
    if (innerStartDate >= innerEndDate) {
      setIsValid(false)
      return
    }
    onChange(innerStartDate, innerEndDate)
    setIsVisible(false)
  }

  let icon
  if (!isValid) {
    icon = <OutlinedCalendarAltIcon color='red' />
  } else if (isVisible) {
    icon = <CheckIcon color='green' />
  } else {
    icon = <OutlinedCalendarAltIcon color='black' />
  }
  const toggleVisible = () => setIsVisible(!isVisible)
  const calendarDatetime = (
    <div className='pf-u-p-sm'>
      <CalendarDatetimeRange
        startDate={innerStartDate}
        endDate={innerEndDate}
        onChangeStart={setInnerStartDate}
        onChangeEnd={setInnerEndDate}
        startLabel={startLabel}
        endLabel={endLabel}
        variant={calendarVariant}
        minEnabled={minEnabled}
        maxEnabled={maxEnabled}
        validators={validators}
      />
      {!isValid && (
        <Alert isInline variant='warning' titleHeadingLevel='h3' title='Período Inválido'>
          <p>Teste escolher outro período.</p>
        </Alert>
      )}
    </div>
  )
  const toggleButton = (
    <Button className='pf-u-my-sm' variant='control' icon={icon} onClick={toggleVisible} />
  )

  return (
    <div className={'pf-u-mx-sm'}>
      <Split isWrappable>
        <Button className='pf-u-my-sm' variant='control' onClick={toggleVisible}>
          {startLabel}
        </Button>
        <SplitItem isFilled={isMobile}>
          {calendarVariant === 'month' ? (
            <MonthTextInput
              date={innerStartDate}
              showCalendarIcon={false}
              onClick={toggleVisible}
            />
          ) : (
            <CalendarTextInput
              value={innerStartDate}
              onChange={newStartDate => {
                if (newStartDate >= innerEndDate) {
                  setIsValid(false)
                  return
                }
                setIsValid(true)
                onChange(newStartDate, innerEndDate)
              }}
              variant={calendarVariant}
              showCalendarIcon={false}
            />
          )}
        </SplitItem>
        {variant === 'label' && (
          <SplitItem>
            <Button className='pf-u-my-sm' variant='control' onClick={toggleVisible}>
              {endLabel}
            </Button>
          </SplitItem>
        )}
        <SplitItem isFilled={isMobile}>
          {calendarVariant === 'month' ? (
            <MonthTextInput date={innerEndDate} showCalendarIcon={false} onClick={toggleVisible} />
          ) : (
            <CalendarTextInput
              value={innerEndDate}
              onChange={newEndDate => {
                if (innerStartDate >= newEndDate) {
                  setIsValid(false)
                  return
                }
                setIsValid(true)
                onChange(innerStartDate, newEndDate)
              }}
              variant={calendarVariant}
              showCalendarIcon={false}
            />
          )}
        </SplitItem>
        <SplitItem>
          {toggleButton}
          <ModalCalendar isModalOpen={isVisible} onConfirm={handleConfirmar}>
            {calendarDatetime}
          </ModalCalendar>
        </SplitItem>
      </Split>
    </div>
  )
}

export default DatetimeRangePicker
