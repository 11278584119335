import { useEffect, useState } from 'react'

const useVertical = () => {
  const [isVertical, setIsVertical] = useState(window.innerHeight > window.innerWidth)

  useEffect(() => {
    const resizeCallback = () => {
      setIsVertical(window.innerHeight > window.innerWidth)
    }
    window.addEventListener('resize', resizeCallback)
    return () => {
      window.removeEventListener('resize', resizeCallback)
    }
  }, [])

  return isVertical
}

export default useVertical
