import {
  Anexo,
  Cliente,
  ConjuntoTarefas,
  FormaPagamento,
  Peca,
  Relatorio,
  TabelaPrecos,
} from '../../../clients'
import {
  calcTempoCobravel,
  calcValorTotalAtendimento,
  calcValorTotalPecas,
  calcValorTotalServico,
  debouncePutRelatorio,
  getDefaultConjTarefas,
  getDefaultPeca,
} from '../misc'

export enum ACTIONS {
  SET_RELAT,
  SAVE_RELAT,
  SWITCH_CLIENT,
  SWITCH_FORMA_PAGAMENTO,
  SWITCH_TABELA_PRECOS,
  UPDATE_PROP,
  CONJUNTO_TAREFAS_ADD,
  CONJUNTO_TAREFAS_DELETE,
  CONJUNTO_TAREFAS_UPDATE_TITULO,
  TAREFA_ADD,
  TAREFA_DELETE,
  TAREFA_UPDATE,
  PECA_ADD,
  PECA_DELETE,
  PECA_UPDATE,
  ANEXO_ADD,
  ANEXO_DELETE,
  UPDATE_PERIOD,
  APOIO_REMOTO_TOGGLE,
  MANUAL_PERIOD_TOGGLE,
  MANUAL_PERIOD_DATA_REFERENCIA_UPDATE,
  TEMPO_COBRAVEL_AUTO_TOGGLE,
  TEMPO_COBRAVEL_UPDATE,
  ORCAMENTO_PREVIO_TOGGLE,
  ORCAMENTO_PREVIO_UPDATE,
  ACEITAR_REVISAO,
}

export interface Payload {
  relatorios?: Relatorio[]
  id?: number | string
  prop?: { name: string; value: string | number | boolean }
  cliente?: Cliente
  conjuntoTarefas?: ConjuntoTarefas
  peca?: Peca
  formaPagamento?: FormaPagamento
  tabelaPrecos?: TabelaPrecos
  anexos?: Anexo[]
  period?: { hora_inicio: string; hora_fim: string; tempo_cobravel: number }
  periodReferencia?: Date
  valorTotalServico?: number
  orcamentoPrevioTexto?: string
}

export interface IAction {
  type: ACTIONS
  payload: Payload
}

export interface IState {
  relatorio: Relatorio
  relatorioOriginal?: Relatorio
  last_save?: Date
}

export const DEFAULT_STATE: IState = {
  relatorio: {},
  relatorioOriginal: {},
  last_save: undefined,
}

const sum = (a?: number, b?: number) => (a ?? 0) + (b ?? 0)

export const reducer = (state: IState, action: IAction): IState => {
  if (action.type !== ACTIONS.SET_RELAT && Object.keys(state).length === 0) {
    console.error(ACTIONS.SAVE_RELAT, `Store ainda não inicializado execute ACTIONS.SET_RELAT`)
    return state
  }
  // Para não precisar checar novamente em cada action
  if (!state?.relatorio) {
    console.error(ACTIONS.SAVE_RELAT, `Relatório não definido`)
    return state
  }

  const { type, payload } = action
  switch (type) {
    /* ----- ----- */
    case ACTIONS.SET_RELAT:
      // Carga inicial do store. Preferi deixar a inicialização aqui do que na chamada do construtor
      if (!payload.relatorios || payload.relatorios.length === 0 || !payload.id) {
        console.error(ACTIONS.SET_RELAT, 'Payload ou relatId vazios')
        return state
      }
      const relatorio = payload.relatorios.find(r => r.id === payload.id)
      if (!relatorio) {
        console.error(ACTIONS.SET_RELAT, `Nenhum relat encontrado com id ${payload.id}`)
        return state
      }
      return { relatorio }

    /* ----- ----- */
    case ACTIONS.SAVE_RELAT:
      debouncePutRelatorio(state.relatorio)
      return { ...state, last_save: new Date() }

    /* ----- ----- */
    case ACTIONS.SWITCH_CLIENT:
      if (!payload.cliente || !payload.tabelaPrecos || !payload.tabelaPrecos.id) {
        console.error(ACTIONS.SWITCH_CLIENT, 'Cliente ou tabela de preços não definidos')
        return state
      }
      const { cliente } = payload

      const newStateSC = {
        ...state,
        relatorio: {
          ...state.relatorio,
          cliente_id: cliente.id,
          email_to: cliente.email_to,
          email_cc: cliente.email_cc,
          tabela_precos_id: cliente.tabela_precos_id,
          forma_pagamento_id: cliente.forma_pagamento_id,
          valor_total_atendimento: state.relatorio.is_orcamento_previo
            ? state.relatorio.valor_total_atendimento
            : calcValorTotalAtendimento({ ...state.relatorio }, payload.tabelaPrecos),
        },
      }
      debouncePutRelatorio(newStateSC.relatorio)
      return newStateSC

    /* ----- ----- */
    case ACTIONS.UPDATE_PROP:
      if (!payload.prop) {
        console.error(ACTIONS.UPDATE_PROP, 'Defina o nome e valor da prop')
        return state
      }
      const newStateUpdateProp = {
        ...state,
        relatorio: { ...state.relatorio, [payload.prop.name]: payload.prop.value },
      }
      debouncePutRelatorio(newStateUpdateProp.relatorio)
      return newStateUpdateProp

    /* ----- ----- */
    case ACTIONS.APOIO_REMOTO_TOGGLE:
      if (!payload.tabelaPrecos || !payload.tabelaPrecos.id) {
        console.error(ACTIONS.APOIO_REMOTO_TOGGLE, 'Payload faltando tabelaPrecos')
        return state
      }
      const newStateAR = (() => {
        const tempo_cobravel =
          state.relatorio.hora_inicio && state.relatorio.hora_fim
            ? calcTempoCobravel(
                !state.relatorio.apoio_remoto,
                state.relatorio.hora_inicio,
                state.relatorio.hora_fim,
                payload.tabelaPrecos
              )
            : state.relatorio.tempo_cobravel

        // valor_total_servico: calcValorTotalServico({ ...state.relatorio }, payload.tabelaPrecos)

        return {
          ...state,
          relatorio: {
            ...state.relatorio,
            apoio_remoto: !state.relatorio.apoio_remoto,
            tempo_cobravel,
            local_atendimento: '',
            valor_total_servico: state.relatorio.is_orcamento_previo
              ? state.relatorio.valor_total_servico
              : calcValorTotalServico(
                  {
                    ...state.relatorio,
                    apoio_remoto: !state.relatorio.apoio_remoto,
                    tempo_cobravel,
                  },
                  payload.tabelaPrecos
                ),
            valor_total_atendimento: state.relatorio.is_orcamento_previo
              ? state.relatorio.valor_total_atendimento
              : calcValorTotalAtendimento(
                  {
                    ...state.relatorio,
                    apoio_remoto: !state.relatorio.apoio_remoto,
                    tempo_cobravel,
                  },
                  payload.tabelaPrecos
                ),
          },
        }
      })()

      debouncePutRelatorio(newStateAR.relatorio)
      return newStateAR

    /* ----- ----- */
    case ACTIONS.CONJUNTO_TAREFAS_ADD:
      if (!state?.relatorio?.conjuntos_tarefas || state.relatorio.conjuntos_tarefas.length === 0) {
        return {
          ...state,
          relatorio: { ...state.relatorio, conjuntos_tarefas: getDefaultConjTarefas(0) },
        }
      }

      const addId = (() => {
        const sorter = (a: ConjuntoTarefas, b: ConjuntoTarefas) => a.id - b.id
        const sorted = state.relatorio.conjuntos_tarefas.sort(sorter)
        const last = sorted[state.relatorio.conjuntos_tarefas.length - 1]
        if (!last) {
          console.error(ACTIONS.CONJUNTO_TAREFAS_ADD, 'Ultimo item não encontrado')
          return 9999
        }
        return last.id + 1
      })()

      const newStateCTA = {
        ...state,
        relatorio: {
          ...state.relatorio,
          conjuntos_tarefas: [
            ...state.relatorio.conjuntos_tarefas,
            ...getDefaultConjTarefas(addId),
          ],
        },
      }
      debouncePutRelatorio(newStateCTA.relatorio)
      return newStateCTA

    /* ----- ----- */
    case ACTIONS.CONJUNTO_TAREFAS_DELETE:
      if (!state?.relatorio?.conjuntos_tarefas || state.relatorio.conjuntos_tarefas.length === 0) {
        console.error(ACTIONS.CONJUNTO_TAREFAS_DELETE, 'Impossível deletar conjunto de tarefas')
        return state
      }

      const newStateTD = {
        ...state,
        relatorio: {
          ...state.relatorio,
          conjuntos_tarefas: [
            ...state.relatorio.conjuntos_tarefas.filter(c => c.id !== payload.conjuntoTarefas?.id),
          ],
        },
      }
      debouncePutRelatorio(newStateTD.relatorio)
      return newStateTD

    /* ----- ----- */
    case ACTIONS.CONJUNTO_TAREFAS_UPDATE_TITULO:
      if (
        !state?.relatorio?.conjuntos_tarefas ||
        state.relatorio.conjuntos_tarefas.length === 0 ||
        !payload.conjuntoTarefas
      ) {
        console.error(
          ACTIONS.CONJUNTO_TAREFAS_UPDATE_TITULO,
          'Impossível atualizar conjunto de tarefas'
        )
        return state
      }

      const newStateTUD: IState = {
        ...state,
        relatorio: {
          ...state.relatorio,
          conjuntos_tarefas: [
            ...state.relatorio.conjuntos_tarefas.filter(c => c.id !== payload.conjuntoTarefas?.id),
            { ...payload.conjuntoTarefas, titulo: String(payload.prop?.value) },
          ],
        },
      }
      debouncePutRelatorio(newStateTUD.relatorio)
      return newStateTUD

    /* ----- ----- */
    case ACTIONS.TAREFA_ADD:
      if (!state?.relatorio?.conjuntos_tarefas || state.relatorio.conjuntos_tarefas.length === 0) {
        console.error(ACTIONS.TAREFA_ADD, 'Impossível adicionar tarefa')
        return state
      }

      const conjuntoTA = state.relatorio.conjuntos_tarefas.find(c => {
        return c.id === payload.conjuntoTarefas?.id
      })
      if (!conjuntoTA) {
        console.error(ACTIONS.TAREFA_ADD, 'Conjunto não encontrado impossível adicionar tarefa')
        return state
      }

      const outrosConjuntosTA = state.relatorio.conjuntos_tarefas.filter(
        c => c.id !== payload.conjuntoTarefas?.id
      )

      const addTarefaId = (() => {
        return (
          conjuntoTA.tarefas.reduce(
            (a: any, b: any) => {
              return { id: a.id + b.id }
            },
            { id: 0 }
          ).id + 1
        )
      })()

      const newStateATA = {
        ...state,
        relatorio: {
          ...state.relatorio,
          conjuntos_tarefas: [
            ...outrosConjuntosTA,
            {
              ...conjuntoTA,
              tarefas: [...conjuntoTA.tarefas, { id: addTarefaId, descricao: '' }],
            },
          ],
        },
      }
      debouncePutRelatorio(newStateATA.relatorio)
      return newStateATA

    /* ----- ----- */
    case ACTIONS.TAREFA_DELETE:
      if (!state?.relatorio?.conjuntos_tarefas || state.relatorio.conjuntos_tarefas.length === 0) {
        console.error(ACTIONS.TAREFA_DELETE, 'Impossível deletar tarefa')
        return state
      }

      const conjuntoTD = state.relatorio.conjuntos_tarefas.find(
        c => c.id === payload.conjuntoTarefas?.id
      )
      if (!conjuntoTD || !conjuntoTD.tarefas || conjuntoTD.tarefas.length === 0) {
        console.error(ACTIONS.TAREFA_DELETE, 'Conjunto não encontrado impossível deletar tarefa')
        return state
      }

      return {
        ...state,
        relatorio: {
          ...state.relatorio,
          conjuntos_tarefas: [
            ...state.relatorio.conjuntos_tarefas.filter(c => c.id !== payload.conjuntoTarefas?.id),
            { ...conjuntoTD, tarefas: [...conjuntoTD.tarefas.filter(t => t.id !== payload.id)] },
          ],
        },
      }

    /* ----- ----- */
    case ACTIONS.TAREFA_UPDATE:
      if (!state?.relatorio?.conjuntos_tarefas || state.relatorio.conjuntos_tarefas.length === 0) {
        console.error(ACTIONS.TAREFA_UPDATE, 'Impossível atualizar tarefa')
        return state
      }

      const conjuntoTU = state.relatorio.conjuntos_tarefas.find(
        c => c.id === payload.conjuntoTarefas?.id
      )
      if (!conjuntoTU || !conjuntoTU.tarefas || conjuntoTU.tarefas.length === 0) {
        console.error(ACTIONS.TAREFA_DELETE, 'Conjunto não encontrado impossível deletar tarefa')
        return state
      }

      const newStateTU: IState = {
        ...state,
        relatorio: {
          ...state.relatorio,
          conjuntos_tarefas: [
            ...state.relatorio.conjuntos_tarefas.filter(c => c.id !== payload.conjuntoTarefas?.id),
            {
              ...conjuntoTU,
              tarefas: [
                ...conjuntoTU.tarefas.filter(t => t.id !== payload.id),
                { id: Number(payload.id), descricao: String(payload.prop?.value) },
              ],
            },
          ],
        },
      }
      debouncePutRelatorio(newStateTU.relatorio)
      return newStateTU

    case ACTIONS.PECA_ADD:
      if (!state.relatorio.pecas || state.relatorio.pecas.length === 0) {
        return { ...state, relatorio: { ...state.relatorio, pecas: [getDefaultPeca(1)] } }
      }
      const addPecaId = (() => {
        return (
          state.relatorio.pecas.reduce(
            (a: any, b: any) => {
              return { id: a.id + b.id }
            },
            { id: 0 }
          ).id + 1
        )
      })()
      const newStatePecaAdd = {
        ...state,
        relatorio: {
          ...state.relatorio,
          pecas: [...state.relatorio.pecas, getDefaultPeca(addPecaId)],
        },
      }
      debouncePutRelatorio(newStatePecaAdd.relatorio)
      return newStatePecaAdd

    /* ----- ----- */
    case ACTIONS.PECA_DELETE:
      if (!payload.tabelaPrecos || !payload.tabelaPrecos.id) {
        console.error(ACTIONS.PECA_DELETE, 'Tabela de preços faltando no payload')
        return state
      }
      if (!state.relatorio.pecas || state.relatorio.pecas.length === 0) {
        console.error(ACTIONS.PECA_DELETE, 'Impossível deletar peça inexistente')
        return state
      }
      if (state.relatorio.pecas.length === 1) {
        console.error(ACTIONS.PECA_DELETE, 'Impossível deletar ultima peça')
        return state
      }
      let newStatePD: IState = {
        ...state,
        relatorio: {
          ...state.relatorio,
          pecas: [...state.relatorio.pecas.filter(p => p.id !== payload.id)],
        },
      }
      newStatePD = {
        ...newStatePD,
        relatorio: {
          ...newStatePD.relatorio,
          valor_total_atendimento: calcValorTotalAtendimento(
            { ...newStatePD.relatorio },
            payload.tabelaPrecos
          ),
        },
      }

      debouncePutRelatorio(newStatePD.relatorio)
      return newStatePD

    /* ----- ----- */
    case ACTIONS.PECA_UPDATE:
      if (
        !state.relatorio.pecas ||
        state.relatorio.pecas.length === 0 ||
        !payload.prop?.name ||
        !payload.tabelaPrecos ||
        !payload.tabelaPrecos.id
      ) {
        console.error(
          ACTIONS.PECA_UPDATE,
          'Impossível atualizar. Peça ou tabela de precos inexistente'
        )
        return state
      }
      let newStatePU: IState = {
        ...state,
        relatorio: {
          ...state.relatorio,
          pecas: [
            ...state.relatorio.pecas.filter(p => p.id !== payload.peca?.id),
            // TODO: Verificar o cast para Peca é necessário
            { ...payload.peca, [payload.prop.name]: payload.prop?.value } as Peca,
          ],
        },
      }
      newStatePU = {
        ...newStatePU,
        relatorio: {
          ...newStatePU.relatorio,
          valor_total_atendimento: calcValorTotalAtendimento(
            { ...newStatePU.relatorio } as Relatorio,
            payload.tabelaPrecos
          ),
        },
      }
      debouncePutRelatorio(newStatePU.relatorio)
      return newStatePU

    /* ----- ----- */
    case ACTIONS.ANEXO_ADD:
      if (!payload.anexos || payload.anexos.length === 0) {
        console.error(ACTIONS.ANEXO_ADD, 'Payload vazio, impossível adicionar anexo')
        return state
      }
      const newStateAA = {
        ...state,
        relatorio: { ...state.relatorio, anexos: [...payload.anexos] },
      }
      debouncePutRelatorio(newStateAA.relatorio)
      return newStateAA

    /* ----- ----- */
    case ACTIONS.ANEXO_DELETE:
      if (!state.relatorio.anexos || state.relatorio.anexos.length === 0) {
        console.error(ACTIONS.ANEXO_DELETE, 'Sem anexos para deletar ou sem tabela de precos')
        return state
      }
      const newStateAD = {
        ...state,
        relatorio: {
          ...state.relatorio,
          anexos: [...state.relatorio.anexos.filter(a => a.original_filename !== payload.id)],
        },
      }
      debouncePutRelatorio(newStateAD.relatorio)
      return newStateAD

    /* ----- ----- */
    case ACTIONS.SWITCH_FORMA_PAGAMENTO:
      if (!payload.formaPagamento || !payload.formaPagamento.id) {
        console.error(ACTIONS.SWITCH_FORMA_PAGAMENTO, 'Payload sem formaPagamento')
        return state
      }
      const newStateFormaPag = {
        ...state,
        relatorio: { ...state.relatorio, forma_pagamento_id: payload.formaPagamento.id },
      }
      debouncePutRelatorio(newStateFormaPag.relatorio)
      return newStateFormaPag

    /* ----- ----- */
    case ACTIONS.SWITCH_TABELA_PRECOS:
      if (!payload.tabelaPrecos || !payload.tabelaPrecos.id) {
        console.error(ACTIONS.SWITCH_TABELA_PRECOS, 'Payload incompleto')
        return state
      }
      if (state.relatorio.is_orcamento_previo) {
        console.info(
          ACTIONS.SWITCH_TABELA_PRECOS,
          'Tabela de preços não será atualizada quando relatório for orçamento prévio'
        )
        return state
      }
      const newStateTP = {
        ...state,
        relatorio: {
          ...state.relatorio,
          tabela_precos_id: payload.tabelaPrecos.id,
          valor_total_servico: calcValorTotalServico({ ...state.relatorio }, payload.tabelaPrecos),
          valor_total_atendimento: calcValorTotalAtendimento(
            { ...state.relatorio },
            payload.tabelaPrecos
          ),
        },
      }
      debouncePutRelatorio(newStateTP.relatorio)
      return newStateTP

    /* ----- ----- */
    case ACTIONS.UPDATE_PERIOD:
      if (!payload.period || !payload.tabelaPrecos || !payload.tabelaPrecos.id) {
        console.error(ACTIONS.UPDATE_PERIOD, 'Payload missing period or price data')
        return state
      }
      const newStateUP = {
        ...state,
        relatorio: {
          ...state.relatorio,
          hora_inicio: payload.period.hora_inicio,
          hora_fim: payload.period.hora_fim,
          tempo_cobravel: payload.period.tempo_cobravel,
          valor_total_atendimento: state.relatorio.is_orcamento_previo
            ? state.relatorio.valor_total_atendimento
            : calcValorTotalAtendimento(
                {
                  ...state.relatorio,
                  tempo_cobravel: payload.period.tempo_cobravel,
                },
                payload.tabelaPrecos
              ),
          valor_total_servico: state.relatorio.is_orcamento_previo
            ? state.relatorio.valor_total_servico
            : calcValorTotalServico(
                {
                  ...state.relatorio,
                  tempo_cobravel: payload.period.tempo_cobravel,
                },
                payload.tabelaPrecos
              ),
        },
      }
      debouncePutRelatorio(newStateUP.relatorio)
      return newStateUP

    /* ----- ----- */
    case ACTIONS.MANUAL_PERIOD_TOGGLE:
      const newStateManualPeriod = {
        ...state,
        relatorio: {
          ...state.relatorio,
          is_horario_manual: !state.relatorio.is_horario_manual,
          is_tempo_cobravel_manual: !state.relatorio.is_horario_manual,
          hora_fim: undefined,
          tempo_cobravel: 0,
          valor_total_atendimento: 0,
          horario_manual_texto: undefined,
        },
      }
      debouncePutRelatorio(newStateManualPeriod.relatorio)
      return newStateManualPeriod

    /* ----- ----- */
    case ACTIONS.MANUAL_PERIOD_DATA_REFERENCIA_UPDATE:
      if (!payload?.periodReferencia) {
        console.error(
          ACTIONS.TEMPO_COBRAVEL_AUTO_TOGGLE,
          'Payload incompleto, falta periodReferencia'
        )
        return state
      }
      const newStateManualPeriodDataReferencia = {
        ...state,
        relatorio: { ...state.relatorio, hora_inicio: payload.periodReferencia.toISOString() },
      }
      debouncePutRelatorio(newStateManualPeriodDataReferencia.relatorio)
      return newStateManualPeriodDataReferencia

    /* ----- ----- */
    case ACTIONS.TEMPO_COBRAVEL_AUTO_TOGGLE:
      if (!payload.tabelaPrecos?.id) {
        console.error(ACTIONS.TEMPO_COBRAVEL_AUTO_TOGGLE, 'Payload incompleto')
        return state
      }
      if (state.relatorio.is_orcamento_previo) {
        console.info(
          ACTIONS.TEMPO_COBRAVEL_AUTO_TOGGLE,
          'Tempo cobrável não será virado quando relatório for orçamento prévio'
        )
        return state
      }
      const tempo_cobravel_at = (() => {
        const apoio_remoto = !!state.relatorio.apoio_remoto
        const { hora_inicio, hora_fim } = state.relatorio
        if (!hora_inicio || !hora_fim) return 0
        return calcTempoCobravel(apoio_remoto, hora_inicio, hora_fim, payload.tabelaPrecos)
      })()

      const newStateTempoCobravelToggle = {
        ...state,
        relatorio: {
          ...state.relatorio,
          tempo_cobravel: tempo_cobravel_at,
          is_tempo_cobravel_manual: !state.relatorio.is_tempo_cobravel_manual,
          tempo_cobravel_just: ' ',
          valor_total_atendimento: calcValorTotalAtendimento(
            { ...state.relatorio, tempo_cobravel: tempo_cobravel_at },
            payload.tabelaPrecos
          ),
        },
      }
      debouncePutRelatorio(newStateTempoCobravelToggle.relatorio)
      return newStateTempoCobravelToggle

    /* ----- ----- */
    case ACTIONS.TEMPO_COBRAVEL_UPDATE:
      if (!payload.tabelaPrecos?.id || !payload.prop?.value) {
        console.error(ACTIONS.TEMPO_COBRAVEL_UPDATE, 'Payload incompleto')
        return state
      }
      if (state.relatorio.is_orcamento_previo) {
        console.info(
          ACTIONS.TEMPO_COBRAVEL_UPDATE,
          'Tempo cobrável não será atualizado quando relatório for orçamento prévio'
        )
        return state
      }

      const newStateTempoCobravelUpdate: IState = {
        ...state,
        relatorio: {
          ...state.relatorio,
          tempo_cobravel: Number(payload.prop.value),
          valor_total_atendimento: calcValorTotalAtendimento(
            { ...state.relatorio, tempo_cobravel: payload.prop.value as number },
            payload.tabelaPrecos
          ),
          valor_total_servico: calcValorTotalServico(
            { ...state.relatorio, tempo_cobravel: payload.prop.value as number },
            payload.tabelaPrecos
          ),
        },
      }
      debouncePutRelatorio(newStateTempoCobravelUpdate.relatorio)
      return newStateTempoCobravelUpdate

    /* ----- ----- */
    case ACTIONS.ORCAMENTO_PREVIO_TOGGLE:
      const newStateOrcamentoPrevio = {
        ...state,
        relatorio: {
          ...state.relatorio,
          is_orcamento_previo: !state.relatorio.is_orcamento_previo,
          valor_total_servico: payload.tabelaPrecos
            ? calcValorTotalServico({ ...state.relatorio }, payload.tabelaPrecos)
            : 0,
          valor_total_atendimento: sum(
            payload.tabelaPrecos
              ? calcValorTotalServico({ ...state.relatorio }, payload.tabelaPrecos)
              : 0,
            state.relatorio.pecas && state.relatorio.pecas.length > 0
              ? calcValorTotalPecas(state.relatorio.pecas)
              : 0
          ),
          orcamento_previo_texto: '',
          tempo_cobravel_just: undefined,
        },
      }
      debouncePutRelatorio(newStateOrcamentoPrevio.relatorio)
      return newStateOrcamentoPrevio

    /* ----- ----- */
    case ACTIONS.ORCAMENTO_PREVIO_UPDATE:
      const newStateOrcamentoPrevioUpdate = {
        ...state,
        relatorio: {
          ...state.relatorio,
          valor_total_atendimento: sum(
            payload.valorTotalServico === undefined
              ? state.relatorio.valor_total_servico
              : payload.valorTotalServico,
            state.relatorio.pecas && state.relatorio.pecas.length > 0
              ? calcValorTotalPecas(state.relatorio.pecas)
              : 0
          ),
          valor_total_servico:
            payload.valorTotalServico === undefined
              ? state.relatorio.valor_total_servico
              : payload.valorTotalServico,
          orcamento_previo_texto: payload.orcamentoPrevioTexto
            ? payload.orcamentoPrevioTexto
            : state.relatorio.orcamento_previo_texto,
          tempo_cobravel_just: undefined,
        },
      }
      debouncePutRelatorio(newStateOrcamentoPrevioUpdate.relatorio)
      return newStateOrcamentoPrevioUpdate

    /* ----- ----- */
    case ACTIONS.ACEITAR_REVISAO:
      return state

    /* ----- ----- */

    default:
      return state
  }
}
