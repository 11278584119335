import { CalendarProps, Divider, Split, SplitItem, Stack, StackItem } from '@patternfly/react-core'
import { useEffect, useState } from 'react'
import useMobile from '../../hooks/useMobile'
import CalendarDate from './CalendarDate'
import TimeSelector from './TimeSelector'

interface ICalendarDatetimeProps extends CalendarProps {
  startDate?: Date
  endDate?: Date
}

const CalendarDatetime = ({
  date,
  onChange,
  startDate,
  endDate,
  validators,
  ...props
}: ICalendarDatetimeProps) => {
  const [hour, setHour] = useState(date?.getHours())
  const [minute, setMinute] = useState(date?.getMinutes())
  const isMobile = useMobile()

  useEffect(() => {
    if (!date) return
    setHour(date.getHours())
    setMinute(date.getMinutes())
  }, [date])

  const calendarDate = (
    <CalendarDate
      date={date}
      onChange={dt => {
        const prevHour = date ? date.getHours() : 0
        const prevMinute = date ? date.getMinutes() : 0
        dt.setHours(prevHour)
        dt.setMinutes(prevMinute)
        if (!onChange) return
        onChange(new Date(dt.getTime()))
        setHour(prevHour)
        setMinute(prevMinute)
      }}
      validators={validators}
    />
  )
  const hourSelector = (
    <TimeSelector
      type='hours'
      selected={hour}
      onSelect={hour => {
        date?.setHours(hour)
        setHour(hour)
        if (!onChange || !date) return
        onChange(new Date(date.getTime()))
      }}
    />
  )
  const minuteSelector = (
    <TimeSelector
      type='minutes'
      selected={minute}
      onSelect={minute => {
        date?.setMinutes(minute)
        setMinute(minute)
        if (!onChange || !date) return
        onChange(new Date(date.getTime()))
      }}
    />
  )

  return (
    <div {...props}>
      {isMobile ? (
        <Stack>
          <StackItem>
            <Split>
              <SplitItem isFilled> </SplitItem>
              <SplitItem>{calendarDate}</SplitItem>
              <SplitItem isFilled> </SplitItem>
            </Split>
          </StackItem>
          <StackItem>
            <Split>
              <SplitItem isFilled>
                <Divider />
                {hourSelector}
                <Divider />
              </SplitItem>
              <SplitItem>
                <div className='pf-u-mx-sm pf-u-text-align-center'>
                  <Stack>
                    <StackItem isFilled>&nbsp;</StackItem>
                    <StackItem isFilled>
                      <b>&nbsp;&nbsp;:&nbsp;</b>
                    </StackItem>
                    <StackItem isFilled>&nbsp;</StackItem>
                  </Stack>
                </div>
              </SplitItem>
              <SplitItem isFilled>
                <Divider />
                {minuteSelector}
                <Divider />
              </SplitItem>
            </Split>
          </StackItem>
        </Stack>
      ) : (
        <Split>
          <SplitItem>{calendarDate}</SplitItem>
          <SplitItem className='pf-u-mt-md'>{hourSelector}</SplitItem>
          <SplitItem className='pf-u-mt-md'>{minuteSelector}</SplitItem>
        </Split>
      )}
    </div>
  )
}

export default CalendarDatetime
