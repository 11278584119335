import { Relatorio } from '../clients'

const mapFactory = <T>(data: T[]): Map<number, T> => {
  return new Map(data.map((d: any) => [d.id, d])) as Map<number, T>
}

const timeout = (delay: number) => {
  return new Promise(res => setTimeout(res, delay))
}

export { mapFactory, timeout }

export const formatNumeroRelat = (relat: Relatorio) =>
  relat?.numero_relat?.toString().padStart(4, '0')
