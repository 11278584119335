import {
  Button,
  Card,
  CardBody,
  Grid,
  GridItem,
  Popover,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core'
import { ExternalLinkAltIcon, MinusIcon, PlusIcon, ShareSquareIcon } from '@patternfly/react-icons'
import { useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import GlobalContext from '../../contexts/GlobalContext'
import ModalDelete from '../shared/ModalDelete'
import PageFrame from '../shared/PageFrame'

import { RelatorioEnviado } from '../../clients'
import api, { queryClient } from '../../services/api'
import { formatNumeroRelat, timeout } from '../../utils/misc'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const RelatorioCards = () => {
  const {
    isLoading: globalIsLoading,
    relatorios,
    clientes,
    me,
    usuarios,
  } = useContext(GlobalContext)

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [relatToDelete, setRelatToDelete] = useState<number | undefined>(undefined)

  const handleCreateRelatorio = async () => {
    await api.post('relatorios')
    await timeout(500)
    await queryClient.invalidateQueries(['relatorios'])
  }
  const handleDeleteRelatorio = async (relatId?: number) => {
    if (!relatId) return
    await api.delete(`relatorios/${relatId}`)
    await timeout(100)
    await queryClient.invalidateQueries(['relatorios'])
  }

  const { isLoading: enviadosIsLoading, data: relatsEnviados } = useQuery({
    queryKey: ['relatsEnviados'],
    queryFn: () => api.get('relatorios/enviados').then(r => r.data as RelatorioEnviado[]),
    enabled: !!me,
    ...queryOptions,
  })

  const isLoading = globalIsLoading || enviadosIsLoading
  if (isLoading) return <></>
  return (
    <Stack hasGutter>
      <TextContent>
        <Text component={TextVariants.h2}>Rascunhos</Text>
      </TextContent>
      {relatorios
        .filter(
          r =>
            r.revisor_id !== me.current_user.id &&
            ((r.revisor_id && r.relat_revisao_id) || (!r.revisor_id && !r.relat_revisao_id))
        )
        .sort((a, b) => (a?.numero_relat ?? 0) - (b?.numero_relat ?? 0))
        .map((r, i) => {
          const cliente = clientes.get(r?.cliente_id ?? -1)
          const nome_exibicao = cliente?.nome_exibicao ? cliente.nome_exibicao : ''
          const tecnico = usuarios.get(r?.revisor_id ?? -1)
          return (
            <StackItem key={i}>
              <Card>
                <CardBody>
                  <Split hasGutter>
                    <SplitItem>
                      {me.current_user.sigla_tecnico}
                      {formatNumeroRelat(r)} - Relat. {nome_exibicao}
                    </SplitItem>
                    <SplitItem isFilled />
                    {r.relat_revisao_id && (
                      <SplitItem>
                        <Popover
                          aria-label='Basic popover'
                          bodyContent={
                            <div>Relatório enviado para revisão. para {tecnico?.full_name}</div>
                          }
                        >
                          <Button>
                            <ShareSquareIcon />
                          </Button>
                        </Popover>
                      </SplitItem>
                    )}
                    <SplitItem>
                      <Button
                        variant='danger'
                        onClick={() => {
                          setRelatToDelete(r.id)
                          setIsModalDeleteOpen(true)
                        }}
                      >
                        <MinusIcon /> Remover
                      </Button>
                    </SplitItem>
                    <SplitItem>
                      <a href={`editor/${r.id}`}>
                        <Button variant='primary'>
                          <ExternalLinkAltIcon /> Editar
                        </Button>
                      </a>
                    </SplitItem>
                  </Split>
                </CardBody>
              </Card>
            </StackItem>
          )
        })}
      <Button onClick={handleCreateRelatorio}>
        <PlusIcon /> Criar Relatório
      </Button>
      <TextContent>
        <Text component={TextVariants.h2}>Para Revisão</Text>
      </TextContent>
      {relatorios
        .filter(r => r.revisor_id && !r.relat_revisao_id && r.revisor_id !== me.current_user.id)
        .sort((a, b) => (a?.numero_relat ?? 0) - (b?.numero_relat ?? 0))
        .map((r, i) => {
          const cliente = clientes.get(r?.cliente_id ?? -1)
          const nome_exibicao = cliente?.nome_exibicao ? cliente.nome_exibicao : ''
          const tecnico = usuarios.get(r?.revisor_id ?? -1)
          return (
            <StackItem key={i}>
              <Card>
                <CardBody>
                  <Split hasGutter>
                    <SplitItem>
                      {/* TODO: map to relat owner user info */}
                      {tecnico?.sigla_tecnico}
                      {formatNumeroRelat(r)} - Relat. {nome_exibicao}
                    </SplitItem>
                    <SplitItem isFilled />
                    <SplitItem>
                      <a href={`editor/${r.id}`}>
                        <Button variant='primary'>Editar</Button>
                      </a>
                    </SplitItem>
                  </Split>
                </CardBody>
              </Card>
            </StackItem>
          )
        })}
      <TextContent>
        <Text component={TextVariants.h2}>Enviados</Text>
      </TextContent>
      {relatsEnviados && relatsEnviados?.length > 0 ? (
        relatsEnviados.map((r, i) => (
          <StackItem key={i}>
            <Card>
              <CardBody>{r?.subject}</CardBody>
            </Card>
          </StackItem>
        ))
      ) : (
        <></>
      )}
      <ModalDelete
        title='Remover Relatório'
        isModalOpen={isModalDeleteOpen}
        handleModalToggle={() => setIsModalDeleteOpen(!isModalDeleteOpen)}
        handleSubmit={() => handleDeleteRelatorio(relatToDelete)}
        element={relatToDelete}
      />
    </Stack>
  )
}

const Home = () => {
  const { isLoading } = useContext(GlobalContext)
  return (
    <PageFrame pageName='Home' isLoading={isLoading}>
      <Grid hasGutter>
        <GridItem sm={1} md={2} lg={2} xl={3} />
        <GridItem sm={12} md={10} lg={8} xl={6}>
          {!isLoading && <RelatorioCards />}
        </GridItem>
        <GridItem sm={1} md={2} lg={2} xl={3} />
      </Grid>
    </PageFrame>
  )
}
export default Home
