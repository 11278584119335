import { Button, Modal, ModalVariant, Stack, StackItem, TextInput } from '@patternfly/react-core'
import { useContext, useState } from 'react'
import { UserOut } from '../../clients'
import GlobalContext from '../../contexts/GlobalContext'

interface IProps {
  isModalOpen: boolean
  onClose: () => void
  handleSubmit: (newPassword: any) => void
  user?: UserOut
}

const ModalEditUser = ({ isModalOpen, onClose, handleSubmit, user }: IProps) => {
  const { isLoading } = useContext(GlobalContext)
  const [newPass, setNewPass] = useState<string>()
  const [validationErr, setValidationErr] = useState(false)

  if (isLoading || !user) return <></>
  return (
    <Modal
      variant={ModalVariant.medium}
      title={'Editar Usuário'}
      aria-label='modal user edit'
      isOpen={isModalOpen}
      onClose={onClose}
      actions={[
        <Button
          key='create'
          variant='primary'
          form='modal-with-form-form'
          onClick={() => {
            if (!newPass) {
              setValidationErr(true)
              return
            }
            handleSubmit(newPass)
            setValidationErr(false)
          }}
        >
          Salvar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={onClose}>
          Cancelar
        </Button>,
      ]}
    >
      <Stack hasGutter>
        <StackItem>
          Nome
          <TextInput
            placeholder='Nome de exibição do usuário'
            value={user.full_name}
            type='text'
            aria-label='nome_do_usuario'
            isDisabled
          />
        </StackItem>
        <StackItem>
          Email
          <TextInput
            placeholder='Email'
            value={user.email}
            type='text'
            aria-label='email_to_usuário'
            isDisabled
          />
        </StackItem>
        <StackItem>
          Redefinir Senha
          <TextInput
            aria-label='new_passwd_field'
            type='password'
            onChange={value => {
              setNewPass(value)
              setValidationErr(false)
            }}
            validated={validationErr ? 'error' : 'default'}
          />
        </StackItem>
      </Stack>
    </Modal>
  )
}

export default ModalEditUser
