import {
  Button,
  Card,
  CardBody,
  CardTitle,
  ExpandableSection,
  Modal,
  ModalVariant,
  Select,
  SelectOption,
  SelectVariant,
  Spinner,
  Stack,
  StackItem,
} from '@patternfly/react-core'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@patternfly/react-icons'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserOut, Validation } from '../../clients'
import GlobalContext from '../../contexts/GlobalContext'
import api, { queryClient } from '../../services/api'
import { timeout } from '../../utils/misc'
import { sendToRevisao } from './misc'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const fetchRelatValidation = async (relat_id: number) => {
  let data
  try {
    const response = await api.get(`/relatorios/${relat_id}/validate`)
    data = response.data
  } catch (error) {
    console.error(error)
  }
  return data as Validation
}

const AceitarRevisao = () => {
  const { isLoading, relatorio, dispatch } = useContext(Context)
  const { usuarios, me } = useContext(GlobalContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSelectOpen, setSelectOpen] = useState(false)
  const [validation, setValidation] = useState<Validation>()
  const [userSelected, setUserSelected] = useState<UserOut>()
  const navigate = useNavigate()

  if (isLoading) return <></>

  const handleOpenModal = async () => {
    if (!relatorio.id) return
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const handleAccept = () => {
    dispatch({
      type: ACTIONS.ACEITAR_REVISAO,
      payload: {},
    })
  }

  const infoMessages = validation?.messages.filter(m => m.is_valid) || []
  const warningMessages = validation?.messages.filter(m => !m.is_valid && m.can_send) || []
  const erroMessages = validation?.messages.filter(m => !m.is_valid && !m.can_send) || []

  return (
    <>
      <Button variant='primary' onClick={handleOpenModal}>
        Aceitar revisão
      </Button>
      <Modal
        title='Aceitar Revisão'
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        variant={ModalVariant.large}
        actions={[
          <Button key='accept' variant='primary' onClick={handleAccept} isLoading={isSending}>
            {isSending ? 'Enviando' : 'Enviar'}
          </Button>,
          <Button key='cancel' variant='link' onClick={handleCloseModal}>
            Voltar
          </Button>,
        ]}
      >
        <></>
      </Modal>
    </>
  )
}

export default AceitarRevisao
