import { Select, SelectOption, SelectVariant } from '@patternfly/react-core'
import { useContext, useState } from 'react'
import GlobalContext from '../../../contexts/GlobalContext'
import Context from '../store/Context'
import { ACTIONS } from '../store/reducer'

const SelectFormaPagamento = () => {
  const { isLoading, dispatch, relatorio } = useContext(Context)
  const { formaPagamentos } = useContext(GlobalContext)
  const [isOpen, setIsOpen] = useState(false)

  if (isLoading) return <></>

  const formaPgt = formaPagamentos.get(relatorio?.forma_pagamento_id || -1)

  return (
    <Select
      variant={SelectVariant.single}
      onToggle={() => setIsOpen(!isOpen)}
      onSelect={() => setIsOpen(false)}
      selections={formaPgt?.nome}
      isOpen={isOpen}
      placeholderText='Forma de pagamento'
      aria-labelledby='select_forma_pagamento'
      ouiaId={'FormaPagamentoSelectionOuiaId'}
      toggleId={'FormaPagamentoSelectionToggleId'}
    >
      {Array.from(formaPagamentos).map(f => (
        <SelectOption
          key={f[0]}
          value={`${f[1].nome}: '${f[1].texto.substring(0, 50)}...'`}
          onClick={() =>
            dispatch({ type: ACTIONS.SWITCH_FORMA_PAGAMENTO, payload: { formaPagamento: f[1] } })
          }
        />
      ))}
    </Select>
  )
}

export default SelectFormaPagamento
