import { css } from '@patternfly/react-styles'
import styles from '@patternfly/react-styles/css/components/Table/table'
import { TableTextProps } from '@patternfly/react-table'

const TableText = ({
  children = null,
  className = '',
  wrapModifier = 'truncate',
  ...props
}: Partial<TableTextProps>) => (
  <span
    className={css(className, wrapModifier && styles.modifiers[wrapModifier], styles.tableText)}
    {...props}
  >
    {children}
  </span>
)

export default TableText
