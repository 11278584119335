import { Button, Divider, Panel, PanelHeader, PanelMain } from '@patternfly/react-core'
import { EditIcon } from '@patternfly/react-icons'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { useContext, useState } from 'react'
import { UserOut } from '../../clients'
import GlobalContext from '../../contexts/GlobalContext'
import api from '../../services/api'
import ModalEditUser from './ModalEditUser'

const TableUsers = () => {
  const { isLoading, usuarios } = useContext(GlobalContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState<UserOut>()

  const onClose = () => {
    setUser(undefined)
    setModalOpen(!isModalOpen)
  }
  const handleSubmit = async (newPassword: any) => {
    console.log(newPassword)
    if (!user) {
      console.error('User not found, unable to reset password')
      return
    }
    await api.post('/usuarios', { password: newPassword, id: user.id })
    onClose()
  }

  if (isLoading) return <></>
  const panelStyle = { background: 'white' }

  return (
    <>
      <Panel style={panelStyle} className='pf-u-box-shadow-md'>
        <PanelHeader>
          <b>Usuários</b>
        </PanelHeader>
        <Divider />
        <PanelMain>
          <TableComposable variant='compact' gridBreakPoint='grid-lg'>
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Email</Th>
                <Th> </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.from(usuarios).map(u => (
                <Tr key={u[0]}>
                  <Td>{u[1].full_name}</Td>
                  <Td>{u[1].email}</Td>
                  <Td>
                    <Button
                      variant='primary'
                      onClick={() => {
                        setModalOpen(true)
                        setUser(u[1])
                      }}
                    >
                      <EditIcon /> Editar
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </TableComposable>
        </PanelMain>
      </Panel>
          <ModalEditUser
            isModalOpen={isModalOpen}
            onClose={onClose}
            handleSubmit={handleSubmit}
            user={user}
          />
    </>
  )
}

export default TableUsers
