import { useEffect, useState } from 'react'
import brandImg2 from '../../logo.png'

import api from '../../services/api'
import { login } from '../../services/auth'

import { LoginForm, LoginPage, Spinner } from '@patternfly/react-core'
import { ExclamationCircleIcon } from '@patternfly/react-icons'

const PageLogin = () => {
  useEffect(() => {
    document.title = 'Relator'
  }, [])
  const [showHelperText, setShowHelperText] = useState(false)
  const [username, setUsername] = useState('')
  const [isValidUsername, setValidUsername] = useState(true)
  const [password, setPassword] = useState('')
  const [isValidPassword, setValidPassword] = useState(true)
  const [isRememberMeChecked, setRememberMeChecked] = useState(true)
  const [isFetchingData, setFetchingData] = useState(false)
  const loginSpinner = <Spinner isSVG size='lg' />

  const handleUsernameChange = value => {
    setUsername(value)
  }

  const handlePasswordChange = value => {
    setPassword(value)
  }
  const onRememberMeClick = () => {
    const remember = !isRememberMeChecked
    setRememberMeChecked(remember)
  }

  const onLoginButtonClick = async event => {
    event.preventDefault()
    if (isFetchingData) return

    const validUsername = !!isValidUsername
    const validPassword = !!isValidPassword

    setValidUsername(validUsername)
    setValidPassword(validPassword)

    if (username && password) {
      try {
        setFetchingData(true)
        const request = { username, password }
        const data = Object.keys(request)
          .map(key => `${key}=${encodeURIComponent(request[key])}`)
          .join('&')
        const response = await api.post('/login', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        const token = response.data.access_token
        const remenberMe = isRememberMeChecked
        login(token, remenberMe)
        window.location.href = '/home'
      } catch (err) {
        setFetchingData(false)
        setShowHelperText(true)
      }
    }
  }

  return (
    <LoginPage
      footerListVariants='inline'
      brandImgSrc={brandImg2}
      brandImgAlt='DrSYS'
      loginTitle='Faça login na sua conta'
    >
      <LoginForm
        showHelperText={showHelperText}
        helperText='Credenciais Inválidas'
        helperTextIcon={<ExclamationCircleIcon />}
        usernameLabel='Email'
        usernameValue={username}
        onChangeUsername={handleUsernameChange}
        isValidUsername={isValidUsername}
        passwordLabel='Senha'
        passwordValue={password}
        isShowPasswordEnabled
        onChangePassword={handlePasswordChange}
        isValidPassword={isValidPassword}
        loginButtonLabel={isFetchingData ? loginSpinner : 'Login'}
        rememberMeLabel='Mantenha-me conectado.'
        isRememberMeChecked={isRememberMeChecked}
        onChangeRememberMe={onRememberMeClick}
        onLoginButtonClick={onLoginButtonClick}
      />
    </LoginPage>
  )
}
export default PageLogin
