import { Button, Modal, ModalVariant } from '@patternfly/react-core'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GlobalContext from '../../contexts/GlobalContext'
import { queryClient } from '../../services/api'
import { timeout } from '../../utils/misc'
import { finishReview } from './misc'
import Context from './store/Context'

const ConcluirRevisao = () => {
  const { isLoading, relatorio } = useContext(Context)
  const { usuarios } = useContext(GlobalContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()

  if (isLoading) return <></>

  const handleFinishReview = async () => {
    if (!relatorio.id) return
    const sendStatus = await finishReview(relatorio.id)
    console.log(sendStatus)
    setIsSending(true)
    await timeout(2500)
    await queryClient.invalidateQueries(['relatorios'])
    navigate('/')
  }

  const tecnico = usuarios.get(relatorio?.revisor_id ?? -1)

  return (
    <>
      <Button variant='primary' onClick={() => setModalOpen(!isModalOpen)}>
        Notificar Conclusão
      </Button>
      <Modal
        title='Notificar Conclusão de Revisão'
        isOpen={isModalOpen}
        variant={ModalVariant.small}
        actions={[
          <Button
            key={'confirm'}
            variant='primary'
            onClick={handleFinishReview}
            isLoading={isSending}
          >
            {isSending ? 'Enviando' : 'Enviar'}
          </Button>,
          <Button key='cancel' variant='link' onClick={() => setModalOpen(false)}>
            Voltar
          </Button>,
        ]}
      >
        Informar {tecnico?.full_name ?? 'autor'} que a revisão foi concluída?
      </Modal>
    </>
  )
}

export default ConcluirRevisao
