import { Button, Divider, Panel, PanelHeader, PanelMain } from '@patternfly/react-core'
import { EditIcon, PlusIcon, TrashIcon } from '@patternfly/react-icons'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { useContext, useState } from 'react'
import { Cliente } from '../../clients'
import TableText from '../../components/TableText'
import GlobalContext from '../../contexts/GlobalContext'
import api, { queryClient } from '../../services/api'
import ModalDelete from '../shared/ModalDelete'
import ModalCreateCliente from './ModalCreateCliente'

const TableClientes = () => {
  const { clientes, isLoading, precos, formaPagamentos } = useContext(GlobalContext)
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [isModalCreateOpen, setModalCreateOpen] = useState(false)
  const [isModalEditOpen, setModalEditOpen] = useState(false)
  const [clienteIdToDelete, setClienteIdToDelete] = useState<number>()
  const [cliente, setCliente] = useState<Cliente>()

  const handleModalDeleteToggle = (clienteId: number | undefined) => {
    if (!clienteId) return
    setModalDeleteOpen(!isModalDeleteOpen)
    setClienteIdToDelete(clienteId)
  }
  const handleDeleteSubmit = async (clienteId: number | undefined) => {
    if (!clienteId) return
    setModalDeleteOpen(false)
    const response = await api.delete(`/clientes/${clienteId}`)
    console.log(response?.data, 'deleted')
    await queryClient.invalidateQueries(['clientes'])
  }
  const handleEditModalToggle = (clienteId: number | undefined) => {
    if (!clienteId) return
    setCliente(clientes.get(clienteId))
    setModalEditOpen(true)
  }
  const handleCreateCliente = async (newCliente: Cliente) => {
    setModalCreateOpen(false)
    await api.post('clientes', newCliente)
    await queryClient.invalidateQueries(['clientes'])
  }
  const handleEditCliente = async (newCliente: Cliente) => {
    setModalEditOpen(false)
    const postData = cliente ? { id: cliente.id, ...newCliente } : { ...newCliente }
    await api.put('clientes', postData)
    await queryClient.invalidateQueries(['clientes'])
  }

  if (isLoading) return <></>

  const panelStyle = { background: 'white' }

  return (
    <>
      <Panel style={panelStyle} className='pf-u-box-shadow-md'>
        <PanelHeader>
          <b>Clientes</b>
        </PanelHeader>
        <Divider />
        <PanelMain>
          <TableComposable variant='compact' gridBreakPoint='grid-2xl'>
            <Thead>
              <Tr>
                <Th modifier='wrap' tooltip={null}>
                  Nome de Exibição
                </Th>
                <Th modifier='wrap' tooltip={null} width={10}>
                  Email(s) Destino
                </Th>
                <Th modifier='wrap' tooltip={null} width={10}>
                  Email(s) em Cópia
                </Th>
                <Th modifier='wrap' tooltip={null}>
                  Tabela de Preços
                </Th>
                <Th modifier='wrap' tooltip={null}>
                  Forma de pagamento
                </Th>
                <Th modifier='wrap' tooltip={null}>
                  Exibir Valores
                </Th>
                <Th modifier='wrap' tooltip={null}>
                  Tem contrato
                </Th>
                <Th modifier='wrap' tooltip={null}>
                  {' '}
                </Th>
                <Th modifier='wrap' tooltip={null}>
                  {' '}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.from(clientes).map(cr => {
                const [i, c] = cr
                return (
                  <Tr key={i}>
                    <Td dataLabel='Nome de Exibição'>
                      <TableText>{c.nome_exibicao}</TableText>
                    </Td>
                    <Td dataLabel='Email Destino'>
                      <TableText>{c.email_to}</TableText>
                    </Td>
                    <Td dataLabel='Email em Cópia'>
                      <TableText>{c.email_cc}</TableText>
                    </Td>
                    <Td dataLabel='Tabela de Preços'>{precos.get(c.tabela_precos_id)?.nome}</Td>
                    <Td dataLabel='Forma de pagamento'>
                      {formaPagamentos.get(c.forma_pagamento_id)?.nome}
                    </Td>
                    <Td dataLabel='Exibir Valores'>{c.exibir_valores ? 'Sim' : 'Não'}</Td>
                    <Td dataLabel='Tem contrato'>{c.is_contrato ? 'Sim' : 'Não'}</Td>
                    <Td dataLabel='Editar'>
                      <Button variant='primary' onClick={() => handleEditModalToggle(c?.id)}>
                        <EditIcon /> Editar
                      </Button>
                    </Td>
                    <Td dataLabel='Apagar'>
                      <Button variant='danger' onClick={() => handleModalDeleteToggle(c?.id)}>
                        <TrashIcon />
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </TableComposable>
        </PanelMain>
      </Panel>
      <Button className='pf-u-my-md' variant='primary' onClick={() => setModalCreateOpen(true)}>
        <PlusIcon /> Adicionar Cliente
      </Button>
      <ModalDelete
        isModalOpen={isModalDeleteOpen}
        handleModalToggle={() => setModalDeleteOpen(!isModalDeleteOpen)}
        handleSubmit={handleDeleteSubmit}
        title='Remover Cliente'
        element={clienteIdToDelete}
      />
      <ModalCreateCliente
        isModalOpen={isModalEditOpen}
        handleModalToggle={() => setModalEditOpen(!isModalEditOpen)}
        handleSubmit={handleEditCliente}
        cliente={cliente}
      />
      <ModalCreateCliente
        isModalOpen={isModalCreateOpen}
        handleModalToggle={() => setModalCreateOpen(!isModalCreateOpen)}
        handleSubmit={handleCreateCliente}
      />
    </>
  )
}

export default TableClientes
