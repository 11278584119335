import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import Login from './features/login'
import Home from './features/home'
import Editor from './features/editor'
import NotFound from './features/not-found'
import Dashboard from './features/dashboard'

import { isAuthenticated } from './services/auth'

import { GlobalProvider } from './contexts/GlobalContext'

const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  if (!isAuthenticated()) {
    return <Navigate to={redirectPath} replace />
  }

  const content = children || <Outlet />

  return (
    <GlobalProvider>
      {content}
    </GlobalProvider>
  )
}

export default function AppRouter () {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Home />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='home' element={<Home />} />
        <Route path='editor/:relatId' element={<Editor />} />
      </Route>
    </Routes>
  )
}
