import { useContext, useState } from 'react'
import DatetimeRangePicker from '../../../components/calendar/DatetimeRangePicker'
import GlobalContext from '../../../contexts/GlobalContext'
import { getTodayMidnight } from '../../../utils/datetime'
import ValidationAlert from '../../shared/ValidationAlert'
import { calcTempoCobravel } from '../misc'
import Context from '../store/Context'
import { ACTIONS } from '../store/reducer'

const TimeRangePicker = () => {
  const { isLoading, dispatch, relatorio } = useContext(Context)

  const { precos } = useContext(GlobalContext)

  const [isDateValid, setIsDateValid] = useState(true)
  const [invalidDateMessage, setInvalidDateMessage] = useState('')

  if (isLoading) return null

  const handleUpdate = (inicio: Date, fim: Date) => {
    if (!inicio && !fim) {
      console.error('TimeRangePicker.handleUpdate: Inicio ou fim devem ser definidos')
      return
    }
    const { tabela_precos_id, hora_inicio, hora_fim, apoio_remoto } = relatorio
    if (!tabela_precos_id) {
      setInvalidDateMessage('Escolha o cliente antes de definir os horários de atendimento')
      setIsDateValid(false)
      return
    }
    const _ini = inicio ? inicio.toISOString() : hora_inicio || '2020-01-01'
    const _fim = fim ? fim.toISOString() : hora_fim || getTodayMidnight().toISOString()

    const iniDate = Date.parse(_ini)
    const fimDate = Date.parse(_fim)

    if (iniDate >= fimDate) {
      setInvalidDateMessage('Hora inicial não deve ser igual ou maior que a final')
      setIsDateValid(false)
      return
    }

    const tabelaPrecos = precos.get(tabela_precos_id)

    if (!tabelaPrecos) {
      setInvalidDateMessage('Tabela de preços não encontrada, escolha outra')
      setIsDateValid(false)
      return
    }

    const tempo_cobravel = calcTempoCobravel(!!apoio_remoto, _ini, _fim, tabelaPrecos)

    dispatch({
      type: ACTIONS.UPDATE_PERIOD,
      payload: { tabelaPrecos, period: { hora_inicio: _ini, hora_fim: _fim, tempo_cobravel } },
    })
    setIsDateValid(true)
    setInvalidDateMessage('')
  }

  return (
    <div key={`${relatorio?.hora_inicio}_${relatorio?.hora_fim}`}>
      {!isDateValid && <ValidationAlert message={invalidDateMessage} />}
      <DatetimeRangePicker
        startDate={relatorio?.hora_inicio ? new Date(relatorio.hora_inicio) : new Date()}
        endDate={relatorio?.hora_fim ? new Date(relatorio.hora_fim) : new Date()}
        onChange={handleUpdate}
      />
    </div>
  )
}

export default TimeRangePicker
