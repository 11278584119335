import { Button, Flex, FlexItem, Modal, ModalVariant } from '@patternfly/react-core'

const handleLogOut = () => (window.location.href = '/login')

const ModalSessionExpired = ({ isModalOpen }: { isModalOpen: boolean }) => {
  return (
    <Modal
      variant={ModalVariant.small}
      title='Sessão expirada'
      titleIconVariant='warning'
      aria-label='sessão expirada'
      className='session-modal'
      id='session-modal'
      isOpen={isModalOpen}
      disableFocusTrap
      showClose={false}
    >
      Sua sessão vai espirar em poucos segundos. Faça o login novamente.
      <Flex key='session-flex-action' style={{ width: '100%' }}>
        <FlexItem align={{ default: 'alignRight' }}>
          <Button
            key='new-login'
            variant='primary'
            id='session-modal-button'
            onClick={handleLogOut}
            className='green'
          >
            Fazer login
          </Button>
        </FlexItem>
      </Flex>
    </Modal>
  )
}

export default ModalSessionExpired
