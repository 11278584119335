import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  NumberInput,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  TextContent,
  TextInput,
  TextVariants,
} from '@patternfly/react-core'
import { useContext, useState } from 'react'
import { Peca } from '../../clients'
import GlobalContext from '../../contexts/GlobalContext'
import { formatBRL, IntegerToBRL } from '../../utils/currency'
import { calcValorTotalPecas } from '../editor/misc'
import ModalDelete from '../shared/ModalDelete'
import MonetaryInput from '../shared/MonetaryInput'
import Context from './store/Context'
import { ACTIONS } from './store/reducer'

const Pecas = ({ peca }: { peca: Peca }) => {
  const { isLoading, dispatch, relatorio } = useContext(Context)
  const { precos } = useContext(GlobalContext)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)

  if (isLoading) return <></>

  const handleUpdate = (attr: string, value: number | string | boolean) => {
    const tabelaPrecos = precos.get(relatorio.tabela_precos_id || -1)
    dispatch({
      type: ACTIONS.PECA_UPDATE,
      payload: { tabelaPrecos, peca, prop: { name: attr, value } },
    })
  }
  const onChange = (value: number) => {
    handleUpdate('valor_unitario', Math.trunc(value))
  }
  const handleDelete = () => {
    const tabelaPrecos = precos.get(relatorio.tabela_precos_id || -1)
    dispatch({ type: ACTIONS.PECA_DELETE, payload: { id: peca.id, tabelaPrecos } })
  }

  return (
    <>
      <ModalDelete
        title='Remover Peça'
        isModalOpen={isModalDeleteOpen}
        handleModalToggle={() => setIsModalDeleteOpen(!isModalDeleteOpen)}
        handleSubmit={handleDelete}
      />
      <Stack hasGutter>
        <StackItem>
          <Split hasGutter isWrappable>
            <SplitItem isFilled>
              <TextInput
                placeholder='Nome da peça, material ou equipamento.'
                value={peca.nome}
                validated={peca.nome.length < 400 ? 'default' : 'error'}
                type='text'
                aria-label='peca-nome'
                id={`nome_peca_${peca.id}`}
                max={254}
                onChange={text => handleUpdate('nome', text)}
              />
            </SplitItem>
            <SplitItem isFilled>
              <Checkbox
                label='Reembolsado'
                isChecked={peca.is_reembolsado}
                onChange={checked => handleUpdate('is_reembolsado', checked)}
                id={`reembolsado-check_${peca?.id}_${peca.is_reembolsado}`}
                name={`reembolsado-check_${peca?.id}_${peca.is_reembolsado}`}
                className='pf-u-m-sm'
              />
            </SplitItem>
          </Split>
        </StackItem>
        <StackItem>
          <Split hasGutter isWrappable>
            <SplitItem isFilled>
              <MonetaryInput
                value={peca.valor_unitario}
                onChange={onChange}
                className='pf-u-my-sm'
              />
            </SplitItem>
            <SplitItem isFilled>
              <NumberInput
                value={peca.quantidade}
                onChange={(event: any) =>
                  handleUpdate('quantidade', parseInt(event?.target?.value) || 1)
                }
                onMinus={() => handleUpdate('quantidade', peca.quantidade - 1)}
                onPlus={() => handleUpdate('quantidade', peca.quantidade + 1)}
                inputName='input_peca_quantidade'
                inputAriaLabel='number_input_peca_quantidade'
                minusBtnAriaLabel='minus_input_peca_quantidade'
                plusBtnAriaLabel='plus_input_peca_quantidade'
                unitPosition='before'
                unit='Qtd'
                className='pf-u-my-sm'
                min={1}
                widthChars={3}
              />
            </SplitItem>
            <SplitItem isFilled>
              <TextContent>
                <TextInput
                  value={'R$ ' + formatBRL(IntegerToBRL(peca.valor_unitario * peca.quantidade))}
                  type='text'
                  aria-label={`total_peca_${peca.id}`}
                  id={`id_total_peca_${peca.id}`}
                  isDisabled
                  className='pf-u-my-sm'
                  style={{ maxWidth: '9rem' }}
                />
              </TextContent>
            </SplitItem>
            <SplitItem isFilled>
              <Button
                variant='secondary'
                className='pf-u-my-sm'
                isDisabled={relatorio?.pecas?.length === 1}
                onClick={() => setIsModalDeleteOpen(!isModalDeleteOpen)}
                isDanger
              >
                Remover
              </Button>
            </SplitItem>
          </Split>
        </StackItem>
        <StackItem isFilled>
          <hr className='pf-u-m-sm' />
        </StackItem>
      </Stack>
    </>
  )
}

const ValoresPecas = () => {
  const { isLoading, relatorio, dispatch } = useContext(Context)
  const { precos } = useContext(GlobalContext)
  if (isLoading) return <></>

  const valorTotalPecas =
    relatorio.pecas && relatorio.pecas.length > 0
      ? formatBRL(IntegerToBRL(calcValorTotalPecas(relatorio.pecas)))
      : 0

  const handleAddPeca = () => {
    const tabelaPrecos = precos.get(relatorio.tabela_precos_id || -1)
    dispatch({ type: ACTIONS.PECA_ADD, payload: { tabelaPrecos } })
  }

  return (
    <Stack hasGutter>
      <StackItem>
        <TextContent>
          <Text component={TextVariants.h2}>Valores das Peças</Text>
        </TextContent>
      </StackItem>
      <StackItem>
        <Card>
          <CardBody>
            <Stack hasGutter>
              {relatorio.pecas
                ?.sort((a, b) => a.id - b.id)
                .map((p) => (
                  <StackItem key={p.id}>
                    <Pecas peca={p} />
                  </StackItem>
                ))}
            </Stack>
          </CardBody>
          <CardFooter>
            <Split>
              <SplitItem>
                <Button variant='primary' onClick={handleAddPeca}>
                  Adicionar Peça
                </Button>
              </SplitItem>
              <SplitItem isFilled />
              <SplitItem>
                Total:{' '}
                <TextInput
                  value={`R$ ${valorTotalPecas}`}
                  type='text'
                  aria-label='total_pecas'
                  id='id_total_pecas'
                  isDisabled
                  className='pf-u-my-sm'
                  style={{ maxWidth: '9rem' }}
                />
              </SplitItem>
            </Split>
          </CardFooter>
        </Card>
      </StackItem>
    </Stack>
  )
}

export default ValoresPecas
